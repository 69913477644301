import { useQuery } from "react-query";
import {
  fetchAllModules,
  fetchCalendariCal,
  fetchDirectLocals,
  fetchReservations,
} from "../shared/queries";
import dayjs from "dayjs";
import { BaseSelectItem, CalendarDay, Local, ReservationTable } from "../types";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import ReservationDetail from "../components/calendar/ReservationDetail";
import ApartmentRow from "../components/calendar/ApartmentRow";
import DatePicker from "../components/DatePicker";
import weekday from "dayjs/plugin/weekday";
import { useHistory } from "react-router-dom";
import Icon from "../components/Icon";
// import useBreadcrumbs from "../hooks/useBreadcrumbs";
import { useTranslation } from "react-i18next";
import Checkbox from "../components/Checkbox";
import SelectInput from "../components/SelectInput";
import { moduleId } from "../shared/utils/config";

dayjs.extend(weekday);

export default function Calendar() {
  const history = useHistory();
  const { t } = useTranslation();

  const [days, setDays] = useState<CalendarDay[]>([]);
  const [startDate, setStartDate] = useState<string>(
    dayjs().format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(
    dayjs().add(20, "days").format("YYYY-MM-DD")
  );
  const [currentReservation, setCurrentReservation] = useState<any>();
  const [tempLocals, setTempLocals] = useState<string[]>([]);
  const [iCalButton, setiCalButton] = useState(false);
  const [viewIcal, setViewIcal] = useState<boolean>(false);
  const [disableiCal, setDisableiCal] = useState<boolean>(true);
  const [selectStart, setSelectStart] = useState<any>([]);
  function recalcDays() {
    const ds = [];
    let tempDate = dayjs(startDate).clone();
    while (tempDate.isBefore(endDate)) {
      ds.push({
        date: tempDate.format("DD-MM-YYYY"),
        weekday: tempDate.format("dd"),
        day: tempDate.format("DD"),
        dayNumber: tempDate.weekday(),
      } as CalendarDay);
      tempDate = tempDate.add(1, "day");
    }
    setDays(ds);
  }

  // useBreadcrumbs([{ label: "Calendar", route: "/calendar" }]);

  const { data, refetch: refetchReservations } = useQuery("calendar", () =>
    fetchReservations(
      [
        {
          key: "1",
          label: "",
          active: true,
          value: `${dayjs(startDate)
            .subtract(15, "days")
            .format("YYYY MM DD")} >>> ${dayjs(endDate).format("YYYY MM DD")}`,
        },
        {
          key: "22",
          label: "",
          active: true,
          value: "0",
        },
      ],
      undefined,
      [],
      800
    )
  );

  const { data: locals, isLoading: isLoadingLocals } = useQuery(
    "localsList",
    fetchDirectLocals,
    {
      onSuccess: (data) => {
        const test: string[] = [];
        data.results.map((acc) => {
          test.push(acc.local_key as string);
          return test;
        });
        setTempLocals(test);
        return data;
      },
    }
  );

  function getLocalReservations(local: Local): ReservationTable[] {
    if (data) {
      const dataTemp = data.data.filter((d) => d.local === local.name);
      const secondDataTemp = getLocalReservationsiCal(local);
      const dataTempFinal = [...dataTemp, ...secondDataTemp];
      /* return data.data.filter((d) => {
        return d.local === local.name;
      }); */
      if (secondDataTemp.length > 0 && viewIcal) {
        return dataTempFinal;
      }
      return dataTemp;
    }
    return [];
  }

  const { data: iCalDT } = useQuery<any>(
    ["icalDT", tempLocals],
    () => fetchCalendariCal(tempLocals),
    {
      enabled: tempLocals.length > 0 && iCalButton,
    }
  );

  function getLocalReservationsiCal(local: Local): ReservationTable[] {
    if (iCalDT) {
      return iCalDT?.results?.filter((d: any) => {
        return d.local_key === local.local_key;
      });
    }
    return [];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: dataModules } = useQuery([], () => {
    return fetchAllModules().then((data) => {
      const moduleCalendarFinded = data?.modules?.find(
        (module: any) => module?.id === moduleId.calendar
      );
      if (moduleCalendarFinded) {
        setiCalButton(true)
        if (moduleCalendarFinded?.active_locals_count === 0) {
          setDisableiCal(true);
        } else {
          setDisableiCal(false);
        }
      } else {
        setiCalButton(false)
        setDisableiCal(true);
      }

      return data;
    });
  });
  useEffect(() => {
    const dataStorage = localStorage.getItem("calendar_filteredLocals");
    if (dataStorage) {
      setSelectStart(JSON.parse(dataStorage));
    }
  }, []);

  useEffect(() => {
    recalcDays();
    refetchReservations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <div className={"select-none"}>
      <div className="flex p-4 pt-0 space-x-2 lg:space-x-0 items-center justify-between">
        <div className="flex items-center space-x-4">
          {/* <div className="font-bold text-gray-800 text-2xl">
            {t("navigation.calendar")}
          </div> */}
          <div className="font-bold text-gray-800 text-2xl">
            {t("reservations.date")}
          </div>

          <div>
            <DatePicker
              value={startDate}
              inputFormat={"YYYY-MM-DD"}
              outputFormat={"YYYY-MM-DD"}
              displayFormat={"DD-MM-YYYY"}
              onChange={(value) => {
                setStartDate(
                  dayjs(value).subtract(1, "days").format("YYYY-MM-DD")
                );
                setEndDate(
                  dayjs(value)
                    .subtract(1, "days")
                    .add(20, "days")
                    .format("YYYY-MM-DD")
                );
              }}
            />
          </div>
          {iCalButton && <div className="bg-white border-gray-300 border-2 rounded-xl px-2">
            <Checkbox
              disabled={disableiCal}
              size="sm"
              checked={viewIcal}
              onChange={() => setViewIcal((prev) => !prev)}
              label={t('calendar.viewiCal')}
            />
          </div>}
        </div>

        <div>
          <button
            className={
              "flex items-center bg-white focus:outline-none py-1 bg-white-600 px-3 text-sm font-medium border-gray-300 rounded-2xl"
            }
            style={{
              boxShadow:
                "rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px",
            }}
            onClick={() => {
              history.push("/reservations/new");
            }}
          >
            <span className={"hidden lg:block"}>
              {t("reservations.newReservation")}
            </span>
            <span className={"block lg:hidden"}>
              <Icon name={"document-add"} size={"26px"} />
            </span>
          </button>
        </div>
      </div>
      <div className="flex justify-center gap-5 text-xs font-bold mb-5">
        <div
          className={
            "font-medium flex items-center text-gray-800 bg-gray-100 rounded-full p-1 border-2 border-gray-800"
          }
        >
          <div className={"w-3 h-3 bg-gray-800 mr-2 rounded-full"}></div>{" "}
          {t("resv.notNecessary")}
        </div>
        <div
          className={
            "font-medium flex items-center text-red-800 bg-red-200 rounded-full p-1 border-2 border-red-800"
          }
        >
          <div className={"w-3 h-3 bg-red-800 mr-2 rounded-full"}></div>{" "}
          {t("resv.notExecuted")}
        </div>
        <div
          className={
            "font-medium flex items-center text-yellow-800 bg-yellow-300 rounded-full p-1 border-2 border-yellow-800"
          }
        >
          <div className={"w-3 h-3 bg-yellow-800 mr-2 rounded-full"}></div>{" "}
          {t("resv.waitingConfirmation")}
        </div>

        <div
          className={
            "font-medium flex items-center text-green-800 bg-green-100 rounded-full p-1 border-2 border-green-800"
          }
        >
          <div className={"w-3 h-3 bg-green-800 mr-2 rounded-full"}></div>{" "}
          {t("resv.confirmed")}
        </div>
      </div>

      <div className="w-full mb-5 flex justify-end items-center gap-5 text-sm">
        <div className="w-96">
          {" "}
          <div>{t('calendar.selectLocalToView')}</div>
          <SelectInput<BaseSelectItem, true>
            isMulti
            value={selectStart}
            // placeholder="Seleziona locals da visualizzare.."
            onChange={(item) => {
              if (item) {
                setSelectStart(item);
                localStorage.setItem(
                  "calendar_filteredLocals",
                  JSON.stringify(item)
                );
              }
            }}
            options={locals?.results?.map((local) => {
              return {
                label: local.name,
                value: local.local_key,
              };
            })}
          />
        </div>
      </div>

      <div className="flex border-t">
        <div className="w-52 bg-gray-100 border-r flex items-center justify-end px-2">
          <Icon
            onClick={() => {
              const newDate = dayjs(startDate).subtract(1, "days");
              setStartDate(newDate.format("YYYY-MM-DD"));
              setEndDate(newDate.add(20, "days").format("YYYY-MM-DD"));
            }}
            className={"cursor-pointer"}
            name={"chevron-left"}
            size={"20px"}
          />
          <Icon
            onClick={() => {
              const newDate = dayjs(startDate).add(1, "days");
              setStartDate(newDate.format("YYYY-MM-DD"));
              setEndDate(newDate.add(20, "days").format("YYYY-MM-DD"));
            }}
            className={"cursor-pointer"}
            name={"chevron-right"}
            size={"20px"}
          />
        </div>
        <div className="flex-1 flex">
          {days?.map((day, index) => (
            <div
              key={index}
              className={`relative flex-1 border-r text-center py-3 font-medium text-sm ${
                day.dayNumber === 0 ? "text-red-600" : "text-gray-500"
              }`}
            >
              {day.weekday} {day.day}
              {index === 0 && (
                <div
                  className={"border-r border-cyan-400"}
                  style={{
                    position: "absolute",
                    right: 0,
                    width: "1px",
                    top: 0,
                    height: "100vh",
                  }}
                />
              )}
            </div>
          ))}
        </div>
      </div>

      {locals && (
        <div className={"border-t"}>
          {locals?.results
            ?.filter((local) => {
              if (selectStart.length > 0) {
                return selectStart?.find(
                  (sel: any) => sel.value === local.local_key
                );
              }
              return local;
            })
            .map((local, index) => (
              <ApartmentRow
                key={index}
                startDate={startDate}
                days={days}
                onView={(r) => {
                  setCurrentReservation(r);
                }}
                local={local}
                reservations={getLocalReservations(local)}
              />
            ))}
        </div>
      )}

      {currentReservation && (
        <motion.div
          initial={{
            right: -200,
          }}
          animate={{
            right: 20,
          }}
          style={{
            top: "0",
            position: "fixed",
            height: "100vh",
            width: "100vw",
            zIndex: 99,
            padding: "2rem",
            display: "flex",
            justifyContent: "flex-end",
          }}
          onClick={(e) => {
            setCurrentReservation(null);
          }}
        >
          <div
            style={{
              width: "50%",
              maxWidth: "600px",
              borderRadius: "6px",
              overflowX: "auto",
              height: "100%",
              boxShadow: "0px 2px 16px rgba(0,0,0,0.3)",
              position: "relative",
              backgroundColor: "#FFF",
            }}
            className={"border border-gray-300"}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              style={{
                padding: "2rem",
              }}
            >
              <ReservationDetail
                reservationId={currentReservation.code}
              ></ReservationDetail>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
}
