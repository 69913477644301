import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import AppRouter from './AppRouter';

import store from './store';

import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { QueryClient, QueryClientProvider } from 'react-query';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ReactGA from 'react-ga';
import i18n from './langs';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// const testKey = 'pk_test_ZvK5P1p036rL8HM0hriBj1oF00twWv6I3H'
const liveKey = 'pk_live_UcDI3fUx9OCTe31pLGDPqDoe00lA6R56p2';

Sentry.init({
  dsn: 'https://ac7b6e49a33e47af8e1bcfd4b2d1d044@o854569.ingest.sentry.io/5819694',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
});

const isAirekey = document.URL.indexOf('airekey') >= 0

const TRACKING_ID = isAirekey? "UA-78083318-1" : "G-NN9H7442E6'";
ReactGA.initialize(TRACKING_ID);

const appFavicon = isAirekey ? 'airekey_favicon.png' : 'favicon.png'

let oldFavicon = document.getElementById('favicon')
var link = document.createElement('link')
link.id = 'favicon';
link.type = 'image/x-icon'
link.rel = 'icon';
link.href = appFavicon +'?=' + Math.random();
if (oldFavicon) {
    document.head.removeChild(oldFavicon);
}
document.head.appendChild(link);

document.title = isAirekey ? "COVID-19! Contactless online Check in for booking hotel, vacation rental, airbnb & short term rentals, airEkey Keyless System": "Vikey"

const stripePromise = loadStripe(liveKey);

function App() {
  useEffect(() => {
    window.Intercom("shutdown");
    window.Intercom("boot", {
      app_id: "ux6abiod",
    });
    window.Intercom('update', {
      "hide_default_launcher": true
    });
}, [])

  return (
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <AppRouter></AppRouter>
          <ToastContainer
            style={{
              borderRadius: '4px',
            }}
            transition={Slide}
          ></ToastContainer>
        </QueryClientProvider>
      </Provider>
    </Elements>
  );
}

export default App;
 