import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { updateApartment, updateApartmentV3 } from "../../shared/queries"
import { Local } from "../../types"
import FormField from "../FormField"
import Modal from "../Modal"
import ModalActions from "../ModalAction"
import ModalCard from "../ModalCard"
import ModalSection from "../ModalSection"
import TextInput from "../TextInput"

type ApartmentUpdateAddressProps = {
  data: Local,
  visible: boolean,
  onClose?: () => void,
  onSave?: () => void
}

export default function ApartmentUpdateAddress ({
  data,
  visible,
  onClose,
  onSave
} : ApartmentUpdateAddressProps) {

  const queryClient = useQueryClient()

  const { t } = useTranslation()

  const [name, setName] = useState<string>(data.name || '')
  const [publicName, setPublicName] = useState<string>(data.public_name || '')
  const [address, setAddress] = useState<string>(data.address || '')
  const [city, setCity] = useState<string>(data.city || '')
  const [zip, setZip] = useState<string>(data.zip || '')
  const [specs, setSpecs] = useState<string>(data.specs || '')
  const [bellName, setBellName] = useState<string>(data.bell_name || '')


  /**
   * Mutate the selected stripe account
   */
  const mutation = useMutation(() => updateApartmentV3(data.local_key || '', {
    name,
    public_name:publicName,
    address,
    city,
    specs,
    zip,
    bell_name: bellName
  }), {
    onSuccess: () => {
      toast.success(t('general.updated'))
      queryClient.invalidateQueries(['apartment', data.local_key])
      if (onClose) onClose()
    }
  })

  return (
    <Modal
      visible={visible}>
      <ModalCard
        title={t('updateAddress.updateAddress')}>
        <ModalSection>
          <div className={'p-4 flex-wrap flex -mx-2'}>
          <FormField
            className={'px-2 mb-4 w-full'}
            label={t('updateAddress.publicName')}>
            <TextInput
              value={publicName}
              onChange={value => setPublicName(value)}></TextInput>
          </FormField>
          <FormField
            className={'px-2 mb-4 w-full'}
            label={t('updateAddress.name')}>
            <TextInput
              value={name}
              onChange={value => setName(value)}></TextInput>
          </FormField>
          <FormField
            className={'px-2 mb-4 w-full'}
            label={t('general.address')}>
            <TextInput
              value={address}
              onChange={value => setAddress(value)}></TextInput>
          </FormField>
          <FormField
            className={'px-2 mb-4 w-full'}
            label={t('general.city')}>
            <TextInput
              value={city}
              onChange={value => setCity(value)}></TextInput>
          </FormField>
          <FormField
            className={'px-2 mb-4 w-full'}
            label={t('updateAddress.zip')}>
            <TextInput
              value={zip}
              onChange={value => setZip(value)}></TextInput>
          </FormField>
          <FormField
            className={'px-2 mb-4 w-full'}
            label={t('updateAddress.bellName')}>
            <TextInput
              onChange={value => setBellName(value)}
              value={bellName}></TextInput>
          </FormField>
          <FormField
            className={'px-2 w-full'}
            label={t('updateAddress.specification')}>
            <TextInput
              onChange={value => setSpecs(value)}
              value={specs}></TextInput>
          </FormField>
        </div>
        </ModalSection>
        <ModalActions
          isLoading={mutation.isLoading}
          saveLabel={t('general.update')}
          onClose={() => {
            if (onClose) onClose()
          }}
          onSave={() => {
            mutation.mutate()
            if (onSave) onSave()
          }}></ModalActions>
      </ModalCard>
    </Modal>
  )

}