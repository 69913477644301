import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingOverlay from "../components/common/LoadingOverlay";
import { attemptAdminLogin } from "../features/login/loginSlice";

export default function AdminLogin () {

    const { user, token } = useParams()

    const dispatch = useDispatch()
    const history = useHistory()

    async function authAdmin () {
        if (!user || !token) {
            return
        }
        const result = await dispatch(attemptAdminLogin({
            username: user,
            password: token
        }))
        if (attemptAdminLogin.fulfilled.match(result)) {
            history.push('/dashboard')
        } else {
            toast.error('Invalid credentials')
        }
    }

    useEffect(() => {
        authAdmin()
    })
    
    return (
        <LoadingOverlay />
    )

}