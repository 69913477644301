import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import ReactTooltip from "react-tooltip";
import IntegrationListItem from "../components/integration/IntegrationListItem";
import IntegrationWizard from "../components/integration/IntegrationWizard";
import Modal from "../components/Modal";
import ModalActions from "../components/ModalAction";
import ModalCard from "../components/ModalCard";
import PageTitle from "../components/PageTitle";
import LoadingIntegrationSkeleton from "../components/integration/LoadingIntegrationSkeleton";
import { fetchAllModules, fetchIntegrations } from "../shared/queries";
import { moduleId } from "../shared/utils/config";
import ModalSection from "../components/ModalSection";
import PageWrapper from "../components/PageWrapper";
import { useHistory } from "react-router-dom";
import Button from "../components/Button";

export default function IntegrationsPage () {

  const { t } = useTranslation()
  const history = useHistory()

  const {
    data,
    isLoading
  } = useQuery('integrations', fetchIntegrations)

  const [groupedIntegrations, setGroupedIntegrations] = useState([])
  const [addConnection, setAddConnection] = useState(false)
  const [activeIntegration, setActiveIntegration] = useState(false);
  const [moduleName, setModuleName] = useState("");
  const [visible, setVisible] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: dataModules } = useQuery([], () => {
    return fetchAllModules().then((data) => {
      const moduleIntegrationFinded = data?.modules?.find(
        (module) => module?.id === moduleId.integration
      );
      if (moduleIntegrationFinded?.active_locals_count === 0) {
        setVisible(true);
        setModuleName(t(`module.id${moduleIntegrationFinded.id}`).toUpperCase());
        setActiveIntegration(false);
      } else {
        setVisible(false);
        setModuleName("");
        setActiveIntegration(true);
      }
      return data;
    });
  });

  useEffect(() => {
    if (!data || !data.results || !data.results.data) return
    setGroupedIntegrations(data.results.data.reduce(function (rv, x) {
      (rv[x['integr_type']] = rv[x['integr_type']] || []).push(x)
      return rv
    }, {}))
  }, [ data ])

  return (
    <div>
      <div className="flex justify-between">
        <div>
          <PageTitle
            title={t('integrationPage.integrations')}
            subtitle={t('integrationPage.integrations')} />
        </div>
        <div>
        <Button
            disabled={!activeIntegration}
            onClick={() => setAddConnection(true)}
            label={t("integrationPage.addIntegration")}
            icon={'document-add'}
            iconSize={'24px'}
          />
        </div>
      </div>
      {
        isLoading ? <LoadingIntegrationSkeleton />:  !activeIntegration?
        <LoadingIntegrationSkeleton />
        :
        <div className="flex space-y-2 flex-wrap">
          {
            Object.keys(groupedIntegrations).map((integrationKey, index) => (
              <div className={'w-full'} key={index}>
                {
                  groupedIntegrations[integrationKey].map((integration, intIndex) => (
                    <div key={intIndex} className={'py-2 w-full'}>
                      { index === 0 && <div className={'font-medium text-gray-700 mb-3'}>{integrationKey}</div> }
                      <IntegrationListItem integration={integration} disable={!activeIntegration} />
                    </div>
                  ))
                }
              </div>
            ))
          }
        </div>
      }

      <ReactTooltip />

      <Modal
        visible={addConnection}>
        <ModalCard className={'w-full max-w-4xl'}>
          <IntegrationWizard
            onComplete={() => setAddConnection(false)} />
          <ModalActions
            className={'border-t'}
            onClose={() => setAddConnection(false)} />
        </ModalCard>
      </Modal>

      <Modal visible={visible} className={"w-full"}>
        <ModalCard
          title={`${moduleName.toUpperCase()} (${t("module.statusInactive")})`}
          className={"w-auto"}
        >
          <ModalSection>
            <PageWrapper>
              <span className="text-gray-600 font-medium">
                {t("module.buyModuleFunctionality", {
                  name: moduleName.toUpperCase(),
                })}
              </span>
            </PageWrapper>
            <ModalActions
              onClose={() => setVisible(false)}
              onSave={() =>
                history.push(`/modules/module#${moduleId.integration}`)
              }
              saveLabel={t('module.purchase')}
            />
          </ModalSection>
        </ModalCard>
      </Modal>
    </div>
  )

}