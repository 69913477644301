import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ApartmentConnection from "../components/apartment/ApartmentConnection";
import ApartmentGeneral from "../components/apartment/ApartmentGeneral";
import ApartmentTax from "../components/apartment/ApartmentTax";
import TabSelector from "../components/TabSelector";
import Icon from "../components/Icon";

import {
  fetchAllModules,
  fetchApartment,
  fetchApartmentStats,
} from "../shared/queries";
import { getHumanDateFromISO } from "../shared/utils/formatting";
import {
  SingleApiResponseV3,
  Local,
  LocalStats,
  SingleApiResponse,
} from "../types";
import ApartmentAutomation from "../components/apartment/ApartmentAutomation";
import ApartmentSubcription from "../components/apartment/ApartmentSubscription";
import ApartmentAccess from "../components/apartment/ApartmentAccess";
import ApartmentContent from "../components/apartment/ApartmentContent";
import ApartmentServices from "../components/apartment/ApartmentService";
import PageWrapper from "../components/PageWrapper";
import { useTranslation } from "react-i18next";
import ApartmentStats from "../components/apartment/ApartmentStats";
import BaseLoadingPage from "../components/common/BaseLoadingPage";
// import useBreadcrumbs from "../hooks/useBreadcrumbs";
import ApartmentConfiguration from "../components/apartment/ApartmentConfiguration";
import ApartmentNotifs from "../components/apartment/ApartmentNotifs";
import ApartmentModularity from "../components/apartment/ApartmentModularity";
import { moduleId } from "../shared/utils/config";
import ApartmentCalendar from "../components/apartment/ApartmentCalendar";
import ApartmentLogo from "../components/apartment/ApartmentLogo";
import ApartmentSettings from "../components/apartment/ApartmentSettings";

type ApartmentViewParams = {
  id: string | undefined;
};

export default function ApartmentView() {
  const { t } = useTranslation();
  const { id } = useParams<ApartmentViewParams>();
  const { hash } = useLocation();

  const history: any = useHistory();

  const tabs = [
    "#general",
    "#settings",
    "#taxes",
    "#content",
    "#logo",
    "#domotic",
    "#services",
    "#access",
    "#subscription",
    "#connections",
    "#stats",
    "#configuration",
    "#notifs",
    "#modularity",
    "#calendar",
  ];
  const [currentTab, setCurrentTab] = useState<string>("general");
  const [calendarTab, setCalendarTab] = useState<boolean>(true);
  const [logoTab, setLogoTab] = useState<boolean>(true);

  const { data, isLoading, refetch } = useQuery<
    SingleApiResponseV3<Local>,
    Error
  >(["apartment", id], () => fetchApartment(id));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: dataModules } = useQuery<any>([id], () => {
    if (id) {
      return fetchAllModules(id).then((data: any) => {
        const moduleCalendarFinded = data?.modules?.find(
          (module: any) => module?.id === moduleId.calendar
        );
        if (moduleCalendarFinded) {
          setCalendarTab(false);
        } else {
          setCalendarTab(true);
        }
        const moduleLogoFinded = data?.modules?.find(
          (module: any) => module?.id === moduleId.logo
        );
        if (moduleLogoFinded) {
          setLogoTab(false);
        } else {
          setLogoTab(true);
        }
        return data;
      });
    }
  });

  const { data: stats } = useQuery<SingleApiResponse<LocalStats>, Error>(
    ["stats", id],
    () => fetchApartmentStats(id, "M", "M")
  );

  /* useBreadcrumbs([
    { label: t("apartments.apartments"), route: "/apartments" },
    {
      label:
        data && data.results && data.results.name ? data.results.name : "...",
      route:
        "/apartments/" +
        (data && data.results && data.results.local_key
          ? data.results.local_key
          : "..."),
    },
  ]); */

  useEffect(() => {
    if (tabs.indexOf(hash) >= 0) {
      setCurrentTab(hash.substring(1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    history.push(window.location.pathname + `#${currentTab}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  return (
    <PageWrapper>
      {isLoading || !data || !data.results ? (
        <BaseLoadingPage />
      ) : (
        <div>
          <div className={"mb-8"}>
            <div
              onClick={() => history.push("/apartments")}
              className={
                "w-fit space-x-2 flex items-center hover:text-gray-800 text-gray-500 cursor-pointer text-sm"
              }
            >
              <Icon
                name={"arrow-narrow-left"}
                className={"mr-2"}
                size={"20px"}
              />{" "}
              {t("general.backToList")}
            </div>
            <div className={"mt-8 mb-4"}>
              <div className={"flex justify-between items-center"}>
                <div>
                  <div className={"text-2xl font-bold"}>
                    {data.results.public_name !== data.results.name
                      ? data.results.public_name + " - " + data.results.name
                      : data.results.public_name}
                  </div>
                  <div className={"text-gray-700"}>
                    {data.results.address || "ND"} - {data.results.city || "ND"}
                  </div>

                  <div className={"py-2 border-t mt-2 border-gray-300 mb-6"}>
                    <div className={"flex py-1"}>
                      <div
                        className={
                          "border-r border-gray-200 text-gray-600 text-sm pr-6 mr-6"
                        }
                      >
                        <div className={"mb-1 font-medium"}>
                          {t("apartment.activation")}
                        </div>
                        <div>
                          {data.results.date
                            ? getHumanDateFromISO(data.results.date, false)
                            : "ND"}
                        </div>
                      </div>

                      <div
                        className={
                          "border-r border-gray-200 text-gray-600 text-sm pr-6 mr-6"
                        }
                      >
                        <div className={"mb-1 font-medium"}>
                          {t("apartment.status")}
                        </div>
                        {data.results.monitor_status === "NOTINIT" && (
                          <div className={"text-purple-600"}>Digital</div>
                        )}
                        {data.results.monitor_status === "ERROR" && (
                          <div className={"text-red-600"}>Errore</div>
                        )}
                        {data.results.monitor_status === "ACTIVE" && (
                          <div className={"text-green-600"}>Online</div>
                        )}
                      </div>

                      <div
                        className={
                          "border-r border-gray-200 text-gray-600 text-sm pr-6 mr-6"
                        }
                      >
                        <div className={"mb-1 font-medium"}>
                          {t("apartment.calls")}
                        </div>
                        <div>
                          {stats && stats.data ? stats.data.tot_calls : "ND"}
                        </div>
                      </div>

                      {/* <div
                        className={
                          "border-gray-200 text-gray-600 text-sm pr-6 mr-6"
                        }
                      >
                        <div className={"mb-1 font-medium"}>
                          {t("apartment.availability")}
                        </div>
                        <div>
                          {stats &&
                            stats.data &&
                            stats.data.active_perc > 80 && (
                              <div className={"text-green-600"}>
                                {stats.data.active_perc}%
                              </div>
                            )}
                          {stats &&
                            stats.data &&
                            stats.data.active_perc < 80 &&
                            stats.data.active_perc > 50 && (
                              <div className={"text-yellow-600"}>
                                {stats.data.active_perc}%
                              </div>
                            )}
                          {stats &&
                            stats.data &&
                            stats.data.active_perc < 50 && (
                              <div className={"text-red-600"}>
                                {stats.data.active_perc}%
                              </div>
                            )}
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <TabSelector
              tabs={[
                {
                  key: "general",
                  label: t("apartment.general"),
                  active: currentTab === "general",
                },
                {
                  key: "settings",
                  label: t("apartment.settings"),
                  active: currentTab === "settings",
                },
                {
                  key: "taxes",
                  label: t("apartment.tax"),
                  active: currentTab === "taxes",
                },
                {
                  key: "content",
                  label: t("apartment.contents"),
                  active: currentTab === "content",
                },
                {
                  key: "logo",
                  label: t("apartment.logo"),
                  active: currentTab === "logo",
                  hidden: logoTab,
                },
                {
                  key: "domotic",
                  label: t("apartment.domotics"),
                  active: currentTab === "domotic",
                },
                {
                  key: "services",
                  label: t("apartment.services"),
                  active: currentTab === "services",
                },
                {
                  key: "access",
                  label: t("apartment.access"),
                  active: currentTab === "access",
                },
                {
                  key: "subscription",
                  label: t("apartment.subscription"),
                  active: currentTab === "subscription",
                },
                {
                  key: "connections",
                  label: t("apartment.connections"),
                  active: currentTab === "connections",
                },
                {
                  key: "stats",
                  label: t("apartment.stats"),
                  active: currentTab === "stats",
                },
                {
                  key: "configuration",
                  label: t("apartment.configuration"),
                  active: currentTab === "configuration",
                },
                {
                  key: "notifs",
                  label: t("profile.notifications"),
                  active: currentTab === "notifs",
                },
                {
                  key: "modularity",
                  label: t("profile.modularity"),
                  active: currentTab === "modularity",
                },
                {
                  key: "calendar",
                  label: t("navigation.calendar"),
                  active: currentTab === "calendar",
                  hidden: calendarTab,
                },
              ]}
              className={"mt-4"}
              onChange={(tab) => {
                setCurrentTab(tab.key);
              }}
            ></TabSelector>
          </div>

          {currentTab === "general" && (
            <ApartmentGeneral data={data.results}></ApartmentGeneral>
          )}
          {currentTab === "settings" && (
            <ApartmentSettings data={data.results}></ApartmentSettings>
          )}

          {currentTab === "connections" && (
            <ApartmentConnection
              data={data.results}
              onRefresh={() => refetch()}
            ></ApartmentConnection>
          )}

          {currentTab === "domotic" && (
            <ApartmentAutomation data={data.results}></ApartmentAutomation>
          )}

          {currentTab === "subscription" && (
            <ApartmentSubcription data={data.results}></ApartmentSubcription>
          )}

          {currentTab === "access" && (
            <ApartmentAccess data={data.results}></ApartmentAccess>
          )}

          {currentTab === "content" && (
            <ApartmentContent data={data.results}></ApartmentContent>
          )}
          {currentTab === "logo" && !logoTab && (
            <ApartmentLogo data={data.results} />
          )}

          {currentTab === "services" && (
            <ApartmentServices data={data.results}></ApartmentServices>
          )}

          {currentTab === "stats" && <ApartmentStats data={data.results} />}

          {currentTab === "taxes" && (
            <ApartmentTax
              data={data.results}
              onRefresh={() => refetch()}
            ></ApartmentTax>
          )}

          {currentTab === "configuration" && (
            <ApartmentConfiguration data={data.results} refetch={refetch} />
          )}

          {currentTab === "notifs" && (
            <ApartmentNotifs data={data.results} refetch={refetch} />
          )}
          {currentTab === "modularity" && (
            <ApartmentModularity data={data.results} refetch={refetch} />
          )}
          {currentTab === "calendar" && !calendarTab && (
            <ApartmentCalendar data={data.results} refetch={refetch} />
          )}
        </div>
      )}
    </PageWrapper>
  );
}
