export const BureaucracyList = [
  {
    name: 'countries.it',
    image: '/images/flags/italy.svg',
    bureaucracy: [
      {
        name: "Portale Alloggiati",
        link: "/bureaucracy/portale-alloggiati",
      },
      {
        name: "MOTouristOffice",
        link: "/bureaucracy/motourist",
      },
      {
        name: "Ross1000",
        link: "/bureaucracy/ross1000",
      },
      {
        name: "Radar",
        link: "/bureaucracy/radar",
      },
      {
        name: "Paytourist",
        link: "/bureaucracy/paytourist",
      },
      {
        name: "Istat Trento",
        link: "/bureaucracy/istat-trento",
      },
      {
        name: "Istat Sicilia",
        link: "/bureaucracy/istat-sicilia",
      },
    ],
  },
  {
    name: 'countries.es',
    image: '/images/flags/spain.svg',
    bureaucracy: [
      {
        name: "Policía Nacional",
        link: "/bureaucracy/policia-nacional",
      },
      {
        name: "Mossos d'Esquadra",
        link: "/bureaucracy/mossos-esquadra",
      },
      {
        name: "Guardia Civil",
        link: "/bureaucracy/guardia-civil",
      },
    ],
  },
  {
    name: 'countries.pt',
    image: '/images/flags/portugal.svg',
    bureaucracy: [
      {
        name: "Sef",
        link: "/bureaucracy/sef",
      },
    ],
  },
  {
    name: 'countries.cz',
    image: '/images/flags/ceca.svg',
    bureaucracy: [
      {
        name: "Uby",
        link: "/bureaucracy/uby",
      },
    ],
  },
];
