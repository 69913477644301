import { useTranslation } from "react-i18next";
import { PerStayPrice } from "../../types";
import FormField from "../FormField";
import Icon from "../Icon";
import TextInput from "../TextInput";

export type ApartmentTaxRowProps = {
  className?: string;
  step: PerStayPrice;
  onChange: (step: PerStayPrice) => void;
  onDelete: () => void;
};

export default function ApartmentTaxRow({
  step,
  className,
  onChange,
  onDelete,
}: ApartmentTaxRowProps) {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-between">
      <div className={"w-full space-y-4  " + className}>
        <FormField label={t("ApartmentTaxStayPriceRow.fromPrice")}>
          <TextInput
            min={0}
            value={step.from_price}
            numbered={true}
            onChange={(value) => {
              if (onChange) {
                if (+value < 0) {
                  onChange({
                    ...step,
                    from_price: 0,
                  });
                  return;
                }
                onChange({
                  ...step,
                  from_price: value,
                });
              }
            }}
          ></TextInput>
        </FormField>

        <FormField
          label={t("ApartmentTaxStayPriceRow.toPrice")}
          tooltip={t("ApartmentTaxStayPriceRow.toPriceText")}
        >
          <TextInput
            min={0}
            max={9999}
            type={"number"}
            value={step.to_price}
            numbered={true}
            onChange={(value) => {
              if (onChange) {
                if (+value < 0) {
                  onChange({
                    ...step,
                    to_price: 0,
                  });
                  return;
                }
                onChange({
                  ...step,
                  to_price: value,
                });
              }
            }}
          ></TextInput>
        </FormField>

        <FormField label={t("ApartmentTaxStayPriceRow.amount")}>
          <TextInput
            min={0}
            max={999}
            type={"number"}
            value={step.amount}
            numbered={true}
            onChange={(value) => {
              if (onChange) {
                if (+value < 0) {
                  onChange({
                    ...step,
                    amount: 0,
                  });
                  return;
                }
                onChange({
                  ...step,
                  amount: value,
                });
              }
            }}
          ></TextInput>
        </FormField>
        <FormField
          tooltip={t("addAppartmentTax.999IfNotProvided")}
          label={t("addAppartmentTax.maxNumberDays")}
        >
          <TextInput
            min={0}
            max={999}
            type={"number"}
            value={step.maxdays}
            numbered={true}
            onChange={(value) => {
              if (onChange) {
                if (+value < 0) {
                  onChange({
                    ...step,
                    maxdays: 0,
                  });
                  return;
                }
                onChange({
                  ...step,
                  maxdays: value,
                });
              }
            }}
          ></TextInput>
        </FormField>
      </div>
      <div onClick={onDelete} className={"pl-6"}>
        <Icon
          className={"text-red-600 cursor-pointer hover:text-red-800"}
          name={"trash"}
          size={"20px"}
        ></Icon>
      </div>
    </div>
  );
}
