import Icon from "./Icon";
import styled from "styled-components";
import { useEffect, useRef } from "react";
import { isDefined } from "../shared/utils/guards";

const CustomInput = styled.input<any>`
  height: 37px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  :focus {
    outline: none;
    box-shadow: 0px 0px 0px 4px #00bde120, 0px 0px 0px 1px #00bde190;
  }
  ${(props) => props.numbered && "-webkit-appearance: none!important;"}
`;

type TextInputProps = {
  type?: string;
  suffix?: string;
  placeholder?: string;
  className?: string;
  value?: string | number;
  onChange?: (value: any) => void;
  numbered?: Boolean;
  max?: number;
  min?: number;
  maxLength?: number;
  size?: string;
  renderButton?: Function;
  autofocus?: boolean;
  disabled?: boolean;
};

export default function TextInput({
  type = "text",
  suffix,
  size,
  placeholder,
  className,
  value = "",
  onChange,
  numbered,
  max,
  min,
  maxLength,
  renderButton,
  autofocus = false,
  disabled = false,
}: TextInputProps) {
  const ref = useRef<any>();

  useEffect(() => {
    if (ref && ref.current) {
      if (autofocus) ref.current.focus();
    }
  }, [ref]);

  return (
    <div className={"relative w-full"}>
      <CustomInput
        disabled={disabled}
        ref={ref}
        placeholder={placeholder}
        type={type}
        value={value}
        maxLength={maxLength || null}
        onWheel={(e: any) => e.target.blur()}
        onKeyDown={(e: any) => {
          if(e.code === 'ArrowDown' || e.code === 'ArrowUp') e.preventDefault()
        }}
        onKeyUp={(e: any) => {
          if(e.code === 'ArrowDown' || e.code === 'ArrowUp') e.preventDefault()
        }}
        className={`w-full bg-white rounded-2xl appearance-none px-2 py-1 ${
          size === "sm" ? "h-8 text-sm" : "h-9"
        } focus:outline-none ${className}`}
        onChange={(event: any) => {
          if (onChange)
            onChange(
              numbered
                ? event.target.value.toString().replace(",", ".")
                : event.target.value
            );
        }}
      />

      {(suffix || numbered || renderButton) && (
        <div
          className={
            "flex py-2 absolute top-0 right-0 flex h-full text-sm text-gray-500 font-medium"
          }
        >
          {renderButton && renderButton()}

          {suffix && (
            <div className={"px-2 border-l flex items-center h-full"}>
              {suffix}
            </div>
          )}

          {numbered && (
            <div className={"border-l flex items-center h-full"}>
              <button
                className={
                  "focus:outline-none cursor-pointer hover:text-cyan-600 px-2 h-full border-r items-center flex justify-center"
                }
                onClick={() => {
                  if (value === undefined) return;
                  let floatVal = parseFloat(value.toString());
                  if (!isNaN(floatVal)) {
                    if (isDefined(max) && floatVal >= max) return;
                    floatVal = floatVal + 1;
                    if (onChange) onChange(floatVal);
                  }
                }}
              >
                <Icon name={"chevron-up"} size={"12px"}></Icon>
              </button>
              <button
                className={
                  "focus:outline-none cursor-pointer hover:text-cyan-600 px-2 h-full items-center flex justify-center"
                }
                onClick={() => {
                  if (value === undefined) return;
                  let floatVal = parseFloat(value.toString());
                  if (!isNaN(floatVal)) {
                    if (isDefined(min) && floatVal <= min) return;
                    floatVal = floatVal - 1;
                    if (onChange) onChange(floatVal);
                  }
                }}
              >
                <Icon name={"chevron-down"} size={"12px"}></Icon>
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
