import { motion } from 'framer-motion';
import CircleSpinner from './CircleSpinner';
import Icon from './Icon';

type CheckboxProps = {
  label: string;
  checked: boolean;
  hint?: string;
  size?: string;
  onChange?: Function;
  disabled?: boolean;
  className?: string;
  loading?: boolean;
};

export default function Checkbox({
  label,
  checked,
  hint,
  size,
  disabled,
  onChange = () => {},
  className,
  loading = false,
}: CheckboxProps) {
  return (
    <div
      className={className}
      style={{
        opacity: disabled ? 0.3 : 1,
      }}
    >
      <label className="flex items-center">
        {loading ? (
          <CircleSpinner color={'primary'} />
        ) : (
          <div
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (!disabled && onChange) onChange();
            }}
            className={
              'w-8 flex justify-start px-1 py-1 relative overflow-hidden'
            }
          >
            <div className={'relative'}>
              <input
                disabled={disabled}
                checked={checked}
                type="checkbox"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (!disabled && onChange) onChange();
                }}
                onChange={() => {}}
                className={`${
                  checked
                    ? 'border ring ring-2 ring-cyan-100 border-cyan-600 bg-cyan-400'
                    : 'border border-gray-300'
                } ${
                  size === 'sm' ? 'h-4 w-4' : 'h-5 w-5'
                } focus:outline-none rounded-full appearance-none cursor-pointer`}
              />

              {checked && (
                <motion.div
                  initial={{
                    opacity: 0,
                    top: size === 'sm' ? 7 : 14,
                  }}
                  animate={{
                    top: size === 'sm' ? 2 : 0,
                    opacity: 1,
                  }}
                  transition={{
                    speed: 0.1,
                  }}
                  className={'cursor-pointer text-white absolute'}
                >
                  <Icon
                    name={'check'}
                    size={size === 'sm' ? '16px' : '20px'}
                  ></Icon>
                </motion.div>
              )}
            </div>
          </div>
        )}
        <span
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (!disabled && onChange) onChange();
          }}
          className={`flex-1 cursor-pointer py-1 px-1 text-gray-700 font-medium ${
            size === 'sm' ? 'text-sm ml-1' : 'ml-2'
          }`}
        >
          {label}
          {hint && (
            <div
              className={
                'leading-relaxed font-normal mt-1 text-sm text-gray-500'
              }
            >
              {hint}
            </div>
          )}
        </span>
      </label>
    </div>
  );
}
