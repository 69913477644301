import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import CredentialList from "../components/bureaucracy/CredentialList";
import ReceiptsList from "../components/bureaucracy/ReceiptsList";
import Modal from "../components/Modal";
import ModalActions from "../components/ModalAction";
import ModalCard from "../components/ModalCard";
import ModalSection from "../components/ModalSection";
import PageWrapper from "../components/PageWrapper";
import PreviousPage from "../components/PreviousPage";
import { fetchAllModules } from "../shared/queries";
import { moduleId } from "../shared/utils/config";

export default function BureaucracySettings() {
  const { type } = useParams<any>();
  const { t } = useTranslation();
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [moduleName, setModuleName] = useState("");
  const [disableCredButton, setDisableCredButton] = useState(true);

  const getIdModule = () => {
    switch (type) {
      case "ross1000":
        return moduleId.auto_istat;
      case "radar":
        return moduleId.auto_istat;
      case "motourist":
        return moduleId.auto_istat;
      case "portale-alloggiati":
        return moduleId.pa;
      case "paytourist":
        return moduleId.paytourist;
      case "istat-trento":
        return moduleId.pa;
      case "istat-sicilia":
        return moduleId.auto_istat;
      case "sef":
        return moduleId.pa;
      case "mossos-esquadra":
        return moduleId.pa;
      case "policia-nacional":
        return moduleId.pa;
      case "guardia-civil":
        return moduleId.pa;
      case "uby":
        return moduleId.pa;
      default:
        break;
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data } = useQuery([], () => {
    if (getIdModule()) {
      fetchAllModules().then((data: any) => {
        const moduleFinded = data?.modules?.find(
          (module: any) => module?.id === getIdModule()
        );
        if (moduleFinded?.active_locals_count === 0) {
          setVisible(true);
          setModuleName(t(`module.id${moduleFinded.id}`).toUpperCase());
          setDisableCredButton(true);
        } else {
          setVisible(false);
          setModuleName("");
          setDisableCredButton(false);
        }
        return data;
      });
    } else {
      setVisible(false);
      setModuleName("");
      setDisableCredButton(false);
    }
  });

  return (
    <PageWrapper>
      <PreviousPage className={"mb-6"} label={t("general.goBack")} />

      <div className="flex flex-wrap flex-col space-y-6 ">
        <div className="w-full ">
          <CredentialList type={type} disableButton={disableCredButton} />
        </div>
        <div className="w-full ">
          <ReceiptsList type={type} />
        </div>
      </div>
      <Modal visible={visible} className={"w-full"}>
        <ModalCard
          title={`${moduleName.toUpperCase()} (${t("module.statusInactive")})`}
          className={"w-auto"}
        >
          <ModalSection>
            <PageWrapper>
              <span className="text-gray-600 font-medium">
                {t("module.buyModuleFunctionality", {
                  name: moduleName.toUpperCase(),
                })}
              </span>
            </PageWrapper>
            <ModalActions
              onClose={() => setVisible(false)}
              onSave={() =>
                history.push(`/modules/module#${getIdModule()}`)
              }
              saveLabel={t('module.purchase')}
            />
          </ModalSection>
        </ModalCard>
      </Modal>
    </PageWrapper>
  );
}
