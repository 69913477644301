import { useState } from "react";
import { useQuery } from "react-query";
import {
  fetchBucketItem,
  fetchPerms,
  fetchBureaucracyRecepits,
  fetchBureaucracyRecepitsRossMille,
  fetchBureaucracyRecepitsRadar,
  fetchBucketAllDocs,
  fetchBureaucracyRecepitsIstatTrento,
} from "../../shared/queries";
import { getFormattedDate, getHumanDateFromISO } from "../../shared/utils/formatting";
import {
  AlloggiatiTable,
  IstatSiciliaTable,
  IstatTrentoTable,
  MapMossos,
  PaytouristTable,
  PoliciaTable,
  RadarTable,
  ReceiptTable,
  RiceStatTable,
  RossMilleTable,
  SefTable,
  TableFilterColumn,
  TableResponse,
  TableSort,
  UbyTable,
} from "../../types";
import BaseTable from "../BaseTable";
import { useTranslation } from "react-i18next";
import { SortingRule } from "react-table";
import { useHistory } from "react-router-dom";
import Modal from "../Modal";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";
import ModalActions from "../ModalAction";
import Button from "../Button";
import { toast } from "react-toastify";

type ReceiptsListProps = {
  type: string;
};

export default function ReceiptsList({ type }: ReceiptsListProps) {
  const [filters, setFilters] = useState<TableFilterColumn[] | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sorting, setSorting] = useState<TableSort[]>([]);
  const [rossMilleID, setRossMilleID] = useState<any>();
  const [visible, setVisible] = useState(false);
  const [rossMilleRecipeiptID, setRossMilleRecipeiptID] = useState<any>();
  const [receiptRequest, setReceiptRequest] = useState<any>();

  const { data, isFetching, refetch } = useQuery<
    TableResponse<ReceiptTable>,
    Error
  >(
    ["receipts", type, filters, (currentPage - 1) * 15, sorting],
    () =>
      fetchBureaucracyRecepits(type, filters, (currentPage - 1) * 15, sorting),
    {
      keepPreviousData: true,
    }
  );

  const {
    data: rossMilleDataReceipt,
    isFetching: isFetchingReceipt,
    isLoading: isLoadingReceipt,
  } = useQuery<any>(
    ["rossMilleDataReceipt", rossMilleID],
    () => {
      if (type === "ross1000" && rossMilleID) {
        fetchBureaucracyRecepitsRossMille(
          (currentPage - 1) * 15,
          rossMilleID
        ).then((data) => setRossMilleRecipeiptID(data));
      }
      if (type === "radar" && rossMilleID) {
        fetchBureaucracyRecepitsRadar((currentPage - 1) * 15, rossMilleID).then(
          (data) => setRossMilleRecipeiptID(data)
        );
      }
      if (type === "istat-trento" && rossMilleID) {
        fetchBureaucracyRecepitsIstatTrento(
          (currentPage - 1) * 15,
          rossMilleID
        ).then((data) => setRossMilleRecipeiptID(data));
      }
    },
    {
      keepPreviousData: true,
    }
  );

  const [accounts, setAccounts] = useState<{}>({});
  const history = useHistory();

  const { data: data1, isLoading: isLoadingAccount } = useQuery(
    "accounts",
    fetchPerms,
    {
      onSuccess: (data) => {
        const obj = {} as any;
        if (data && data.results) {
          data.results.forEach((account) => {
            obj[account.o_user_key] = account.o_name;
          });
          setAccounts(obj);
        }
      },
    }
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: dataReceipt, isLoading: isReceiptLoading } = useQuery(
    ["receiptData", receiptRequest],
    () => {
      if (receiptRequest) fetchBucketAllDocs(receiptRequest);
    }
  );

  function getKeys() {
    switch (type) {
      case "guardia-civil":
        return [
          {
            key: "1",
            label: t("reservations.date"),
          },
          {
            key: "2",
            label: t("reservations.code"),
          },
        ];
      case "mossos-esquadra":
        return [
          {
            key: "1",
            label: t("reservations.date"),
          },
          {
            key: "2",
            label: t("reservations.code"),
          },
        ];
      case "policia-nacional":
        return [
          {
            key: "1",
            label: t("reservations.date"),
          },
          {
            key: "2",
            label: t("reservations.code"),
          },
        ];
      case "portale-alloggiati":
        return [
          {
            key: "3",
            label: t("reservations.date"),
          },
          {
            key: "2",
            label: t("buro.username"),
          },
        ];
      case "paytourist":
        return [
          {
            key: "2",
            label: t("day"),
          },
        ];
      case "istat-trento":
        return [
          {
            key: "3",
            label: t("day"),
          },
        ];
      case "istat-sicilia":
        return [
          {
            key: "3",
            label: t("day"),
          },
        ];
      case "ross1000":
        return [
          {
            key: "2",
            label: t("day"),
          },
          {
            key: "7",
            label: t("reservations.date"),
          },
        ];
      case "radar":
        return [
          {
            key: "2",
            label: t("day"),
          },
          {
            key: "7",
            label: t("reservations.date"),
          },
        ];
      case "sef":
        return [
          {
            key: "6",
            label: t("reservations.date"),
          },
        ];
      case "motourist":
        return [
          {
            key: "1",
            label: t("reservations.date"),
          },
          {
            key: "2",
            label: t("reservations.code"),
          },
        ];
      case "uby":
        return [
          {
            key: "5",
            label: t("reservations.date"),
          },
        ];
      default:
        return [];
    }
  }

  function handleSorting(data: SortingRule<any>[]) {
    const cols = [] as TableSort[];
    data.forEach((f) => {
      const colums = getKeys();
      const columnKey = colums.find((df) => df.label === f.id);
      console.log(columnKey);
      if (columnKey) {
        cols.push({
          columnIndex: columnKey.key,
          desc: f.desc || false,
        });
      }
    });
    setSorting(cols);
  }

  const { t } = useTranslation();

  function getColumns() {
    switch (type) {
      case "guardia-civil":
        return [
          {
            Header: t("reservations.date"),
            accessor: (row: MapMossos) => (
              <>{getHumanDateFromISO(row.date, true)}</>
            ),
          },
          {
            Header: t("reservations.code"),
            accessor: (row: MapMossos) => (
              <>
                <button
                  onClick={() => history.push("/reservations/" + row.resv_key)}
                  className={"text-cyan-600"}
                >
                  {row.resv_key}
                </button>
              </>
            ),
          },
          {
            Header: "Risultato",
            disableSortBy: true,
            accessor: (row: MapMossos) => <>{row.result}</>,
          },
          {
            Header: "File",
            disableSortBy: true,
            accessor: (row: MapMossos) => (
              <>
                <button
                  onClick={() => {
                    var a = document.createElement("a");
                    a.setAttribute(
                      "href",
                      "data:text/tex;charset=utf-8," +
                        encodeURIComponent(row.content)
                    );
                    a.setAttribute("download", row.file + ".txt");
                    a.click();
                    a.remove();
                  }}
                  className={"text-cyan-600"}
                >
                  {row.file}
                </button>
              </>
            ),
          },
          {
            Header: t<string>("buro.status"),
            disableSortBy: true,
            accessor: (row: MapMossos) => (
              <>
                {row.status ? (
                  <div
                    className={
                      "rounded-2xl inline-block text-xs px-2 py-1 bg-green-100 font-medium text-green-800 text-white"
                    }
                  >
                    OK
                  </div>
                ) : (
                  <div
                    className={
                      "rounded-2xl inline-block text-xs px-2 py-1 bg-red-100 font-medium text-red-800 text-white"
                    }
                  >
                    ERROR
                  </div>
                )}
              </>
            ),
          },
        ];
      case "mossos-esquadra":
        return [
          {
            Header: t("reservations.date"),
            accessor: (row: MapMossos) => (
              <>{getHumanDateFromISO(row.date, true)}</>
            ),
          },
          {
            Header: t("reservations.code"),
            accessor: (row: MapMossos) => (
              <>
                <button
                  onClick={() => history.push("/reservations/" + row.resv_key)}
                  className={"text-cyan-600"}
                >
                  {row.resv_key}
                </button>
              </>
            ),
          },
          {
            Header: "Risultato",
            disableSortBy: true,
            accessor: (row: MapMossos) => <>{row.result}</>,
          },
          {
            Header: "File",
            disableSortBy: true,
            accessor: (row: MapMossos) => (
              <>
                <button
                  onClick={() => {
                    var a = document.createElement("a");
                    a.setAttribute(
                      "href",
                      "data:text/tex;charset=utf-8," +
                        encodeURIComponent(row.content)
                    );
                    a.setAttribute("download", row.file + ".txt");
                    a.click();
                    a.remove();
                  }}
                  className={"text-cyan-600"}
                >
                  {row.file}
                </button>
              </>
            ),
          },
          {
            Header: "Link",
            disableSortBy: true,
            accessor: (row: MapMossos) => (
              <>
                <button
                  onClick={() => {
                    fetchBucketItem(row.link, row.file, "pdf");
                  }}
                  className={"text-cyan-600"}
                >
                  {row.file}.pdf
                </button>
              </>
            ),
          },
          {
            Header: t<string>("buro.status"),
            disableSortBy: true,
            accessor: (row: MapMossos) => (
              <>
                {row.status ? (
                  <div
                    className={
                      "rounded-2xl inline-block text-xs px-2 py-1 bg-green-100 font-medium text-green-800 text-white"
                    }
                  >
                    OK
                  </div>
                ) : (
                  <div
                    className={
                      "rounded-2xl inline-block text-xs px-2 py-1 bg-red-100 font-medium text-red-800 text-white"
                    }
                  >
                    ERROR
                  </div>
                )}
              </>
            ),
          },
        ];
      case "policia-nacional":
        return [
          {
            Header: t("reservations.date"),
            accessor: (row: PoliciaTable) => (
              <>{getHumanDateFromISO(row.date, true)}</>
            ),
          },
          {
            Header: t("reservations.code"),
            accessor: (row: PoliciaTable) => (
              <>
                <button
                  onClick={() => history.push("/reservations/" + row.resv_key)}
                  className={"text-cyan-600"}
                >
                  {row.resv_key}
                </button>
              </>
            ),
          },
          {
            Header: t<string>("buro.result"),
            disableSortBy: true,
            accessor: (row: PoliciaTable) => <>{row.result}</>,
          },
          {
            Header: "File",
            disableSortBy: true,
            accessor: (row: PoliciaTable) => (
              <>
                <button
                  onClick={() => {
                    var a = document.createElement("a");
                    a.setAttribute(
                      "href",
                      "data:text/tex;charset=utf-8," +
                        encodeURIComponent(row.content)
                    );
                    a.setAttribute("download", row.file + ".txt");
                    a.click();
                    a.remove();
                  }}
                  className={"text-cyan-600"}
                >
                  {row.file}
                </button>
              </>
            ),
          },
          {
            Header: t<string>("buro.status"),
            disableSortBy: true,
            accessor: (row: PoliciaTable) => (
              <>
                {row.status ? (
                  <div
                    className={
                      "rounded-2xl inline-block text-xs px-2 py-1 bg-green-100 font-medium text-green-800 text-white"
                    }
                  >
                    OK
                  </div>
                ) : (
                  <div
                    className={
                      "rounded-2xl inline-block text-xs px-2 py-1 bg-red-100 font-medium text-red-800 text-white"
                    }
                  >
                    ERROR
                  </div>
                )}
              </>
            ),
          },
        ];
      case "portale-alloggiati":
        return [
          {
            Header: "Account",
            disableSortBy: true,
            accessor: (row: AlloggiatiTable) => (
              <>
                {
                  <div
                    className={
                      "bg-cyan-100 text-cyan-800 text-white inline-block text-xs font-medium px-2 rounded-2xl py-1"
                    }
                  >
                    {(accounts as any)[row.account]}
                  </div>
                }
              </>
            ),
          },
          {
            Header: t("buro.username"),
            accessor: (row: AlloggiatiTable) => <>{row.username}</>,
          },
          {
            Header: t("reservations.date"),
            accessor: (row: AlloggiatiTable) => (
              <>{getHumanDateFromISO(row.date, true)}</>
            ),
          },
          {
            Header: "Link",
            disableSortBy: true,
            accessor: (row: AlloggiatiTable) => (
              <>
                <button
                  onClick={() => {
                    var filename = row.filename;
                    var ext = "zip";
                    if (filename.includes(".pdf")) {
                      filename = filename.replace(".pdf", "");
                      ext = "pdf";
                    }
                    fetchBucketItem(row.link, filename, ext);
                  }}
                  className={"text-cyan-600"}
                >
                  {row.filename}
                </button>
              </>
            ),
          },
        ];
      case "paytourist":
        return [
          {
            Header: t("day"),
            accessor: (row: PaytouristTable) => (
              <>{getHumanDateFromISO(row.day, true)}</>
            ),
          },
          {
            Header: t("buro.fileSent"),
            accessor: (row: PaytouristTable) => (
              <>
                <button
                  onClick={() => {
                    var a = document.createElement("a");
                    a.setAttribute(
                      "href",
                      "data:text/tex;charset=utf-8," +
                        encodeURIComponent(row.file_sent)
                    );
                    a.setAttribute("download", "fileSentId_" + row.id + ".txt");
                    a.click();
                    a.remove();
                  }}
                  className={"text-cyan-600"}
                >
                  {t("buro.fileSentDownload")}
                </button>
              </>
            ),
          },
          {
            Header: t("raw_result"),
            accessor: (row: RossMilleTable) => (
              <>
                <button
                  onClick={() => {
                    var a = document.createElement("a");
                    a.setAttribute(
                      "href",
                      "data:text/tex;charset=utf-8," +
                        encodeURIComponent(row.raw_result)
                    );
                    a.setAttribute(
                      "download",
                      "fileRawID_" + row.id + "_file_received.txt"
                    );
                    a.click();
                    a.remove();
                  }}
                  className={"cursor-pointer text-cyan-600"}
                >
                  {t("buro.fileReceivedDownload")}
                </button>
              </>
            ),
          },
          {
            Header: t("reservations.code"),
            accessor: (row: SefTable) => (
              <>
                <button
                  onClick={() => history.push("/reservations/" + row.resv_key)}
                  className={"text-cyan-600"}
                >
                  {row.resv_key}
                </button>
              </>
            ),
          },
          {
            Header: t<string>("buro.status"),
            disableSortBy: true,
            accessor: (row: UbyTable) => (
              <>
                {row.successfully ? (
                  <div
                    className={
                      "rounded-2xl inline-block text-xs px-2 py-1 bg-green-100 font-medium text-green-800 text-white"
                    }
                  >
                    OK
                  </div>
                ) : (
                  <div
                    className={
                      "rounded-2xl inline-block text-xs px-2 py-1 bg-red-100 font-medium text-red-800 text-white"
                    }
                  >
                    ERROR
                  </div>
                )}
              </>
            ),
          },
        ];
      case "istat-sicilia":
        return [
          {
            Header: t("reservations.code"),
            accessor: (row: IstatSiciliaTable) => (
              <>
                <button
                  onClick={() => history.push("/reservations/" + row.resv_key)}
                  className={"text-cyan-600"}
                >
                  {row.resv_key}
                </button>
              </>
            ),
          },
          {
            Header: t("day"),
            accessor: (row: IstatSiciliaTable) => (
              <>{getFormattedDate(row.date, 'YYYY-MM-DDTHH:mm:ss', 'DD-MM-YYYY HH:mm')}{console.log('aa', row.date)}</>
            ),
          },
          {
            Header: t("buro.fileSent"),
            accessor: (row: IstatSiciliaTable) => (
              <>
                <button
                  onClick={() => {
                    var a = document.createElement("a");
                    a.setAttribute(
                      "href",
                      "data:text/tex;charset=utf-8," +
                        encodeURIComponent(row.request_sent)
                    );
                    a.setAttribute("download", "fileSentId_" + row.id + ".txt");
                    a.click();
                    a.remove();
                  }}
                  className={"text-cyan-600"}
                >
                  {t("buro.fileSentDownload")}
                </button>
              </>
            ),
          },
          {
            Header: t("raw_result"),
            accessor: (row: IstatSiciliaTable) => (
              <>
                <button
                  onClick={() => {
                    var a = document.createElement("a");
                    a.setAttribute(
                      "href",
                      "data:text/tex;charset=utf-8," +
                        encodeURIComponent(row.raw_result)
                    );
                    a.setAttribute(
                      "download",
                      "fileRawID_" + row.id + "_file_received.txt"
                    );
                    a.click();
                    a.remove();
                  }}
                  className={"cursor-pointer text-cyan-600"}
                >
                  {t("buro.fileReceivedDownload")}
                </button>
              </>
            ),
          },
          {
            Header: t<string>("buro.status"),
            disableSortBy: true,
            accessor: (row: IstatSiciliaTable) => (
              <>
                {row.successfully ? (
                  <div
                    className={
                      "rounded-2xl inline-block text-xs px-2 py-1 bg-green-100 font-medium text-green-800 text-white"
                    }
                  >
                    OK
                  </div>
                ) : (
                  <div
                    className={
                      "rounded-2xl inline-block text-xs px-2 py-1 bg-red-100 font-medium text-red-800 text-white"
                    }
                  >
                    ERROR
                  </div>
                )}
              </>
            ),
          },
        ];
      case "istat-trento":
        return [
          {
            Header: t("day"),
            accessor: (row: IstatTrentoTable) => (
              <>{getHumanDateFromISO(row.day, true)}</>
            ),
          },
          {
            Header: t("buro.fileSent"),
            accessor: (row: IstatTrentoTable) => (
              <>
                <button
                  onClick={() => {
                    var a = document.createElement("a");
                    a.setAttribute(
                      "href",
                      "data:text/tex;charset=utf-8," +
                        encodeURIComponent(row.file_sent)
                    );
                    a.setAttribute("download", "fileSentId_" + row.id + ".txt");
                    a.click();
                    a.remove();
                  }}
                  className={"text-cyan-600"}
                >
                  {t("buro.fileSentDownload")}
                </button>
              </>
            ),
          },
          {
            Header: t("raw_result"),
            accessor: (row: IstatTrentoTable) => (
              <>
                <button
                  onClick={() => {
                    var a = document.createElement("a");
                    a.setAttribute(
                      "href",
                      "data:text/tex;charset=utf-8," +
                        encodeURIComponent(row.raw_result)
                    );
                    a.setAttribute(
                      "download",
                      "fileRawID_" + row.id + "_file_received.txt"
                    );
                    a.click();
                    a.remove();
                  }}
                  className={"cursor-pointer text-cyan-600"}
                >
                  {t("buro.fileReceivedDownload")}
                </button>
              </>
            ),
          },
          {
            Header: t<string>("buro.status"),
            disableSortBy: true,
            accessor: (row: IstatTrentoTable) => (
              <>
                {row.successfully ? (
                  <div
                    className={
                      "rounded-2xl inline-block text-xs px-2 py-1 bg-green-100 font-medium text-green-800 text-white"
                    }
                  >
                    OK
                  </div>
                ) : (
                  <div
                    className={
                      "rounded-2xl inline-block text-xs px-2 py-1 bg-red-100 font-medium text-red-800 text-white"
                    }
                  >
                    ERROR
                  </div>
                )}
              </>
            ),
          },
        ];
      case "ross1000":
        return [
          {
            Header: t("day"),
            accessor: (row: RossMilleTable) => (
              <>{getHumanDateFromISO(row.day, true)}</>
            ),
          },
          {
            Header: t("buro.fileSent"),
            accessor: (row: RossMilleTable) => (
              <>
                <button
                  onClick={() => {
                    var a = document.createElement("a");
                    a.setAttribute(
                      "href",
                      "data:text/tex;charset=utf-8," +
                        encodeURIComponent(row.file_sent)
                    );
                    a.setAttribute("download", "fileSentId_" + row.id + ".txt");
                    a.click();
                    a.remove();
                  }}
                  className={"text-cyan-600"}
                >
                  {t("buro.fileSentDownload")}
                </button>
              </>
            ),
          },
          {
            Header: t("raw_result"),
            accessor: (row: RossMilleTable) => (
              <>
                <button
                  onClick={() => {
                    var a = document.createElement("a");
                    a.setAttribute(
                      "href",
                      "data:text/tex;charset=utf-8," +
                        encodeURIComponent(row.raw_result)
                    );
                    a.setAttribute(
                      "download",
                      "fileRawID_" + row.id + "_file_received.txt"
                    );
                    a.click();
                    a.remove();
                  }}
                  className={"cursor-pointer text-cyan-600"}
                >
                  {t("buro.fileReceivedDownload")}
                </button>
              </>
            ),
          },
          {
            Header: t("successfully"),
            accessor: (row: RossMilleTable) => (
              <>{row.successfully.toString()}</>
            ),
          },
          {
            Header: t("result"),
            accessor: (row: RossMilleTable) => <>{row.result}</>,
          },
          {
            Header: t("reservations.date"),
            accessor: (row: RossMilleTable) => (
              <>{getHumanDateFromISO(row.date, true)}</>
            ),
          },
        ];
      case "radar":
        return [
          {
            Header: t("day"),
            accessor: (row: RadarTable) => (
              <>{getHumanDateFromISO(row.day, true)}</>
            ),
          },
          {
            Header: t("buro.fileSent"),
            accessor: (row: RadarTable) => (
              <>
                <button
                  onClick={() => {
                    var a = document.createElement("a");
                    a.setAttribute(
                      "href",
                      "data:text/tex;charset=utf-8," +
                        encodeURIComponent(row.file_sent)
                    );
                    a.setAttribute("download", "fileSentId_" + row.id + ".txt");
                    a.click();
                    a.remove();
                  }}
                  className={"text-cyan-600"}
                >
                  {t("buro.fileSentDownload")}
                </button>
              </>
            ),
          },
          {
            Header: t("raw_result"),
            accessor: (row: RadarTable) => (
              <>
                <button
                  onClick={() => {
                    var a = document.createElement("a");
                    a.setAttribute(
                      "href",
                      "data:text/tex;charset=utf-8," +
                        encodeURIComponent(row.raw_result)
                    );
                    a.setAttribute(
                      "download",
                      "fileRawID_" + row.id + "_file_received.txt"
                    );
                    a.click();
                    a.remove();
                  }}
                  className={"cursor-pointer text-cyan-600"}
                >
                  {t("buro.fileReceivedDownload")}
                </button>
              </>
            ),
          },
          {
            Header: t("successfully"),
            accessor: (row: RadarTable) => (
              <>
                {row.successfully ? (
                  <div
                    className={
                      "rounded-2xl inline-block text-xs px-2 py-1 bg-green-100 font-medium text-green-800 text-white"
                    }
                  >
                    OK
                  </div>
                ) : (
                  <div
                    className={
                      "rounded-2xl inline-block text-xs px-2 py-1 bg-red-100 font-medium text-red-800 text-white"
                    }
                  >
                    ERROR
                  </div>
                )}
              </>
            ),
          },
          /* {
            Header: t("result"),
            accessor: (row: RadarTable) => <>{row.result}</>,
          }, */
          {
            Header: t("reservations.date"),
            accessor: (row: RadarTable) => (
              <>{getHumanDateFromISO(row.date, true)}</>
            ),
          },
        ];
      case "motourist":
        return [
          {
            Header: t("reservations.date"),
            accessor: (row: RiceStatTable) => (
              <>{getHumanDateFromISO(row.date, false)}</>
            ),
          },
          {
            Header: t("reservations.code"),
            accessor: (row: RiceStatTable) => (
              <>
                <button
                  onClick={() => history.push("/reservations/" + row.resv_key)}
                  className={"text-cyan-600"}
                >
                  {row.resv_key}
                </button>
              </>
            ),
          },
          {
            Header: t("buro.fileSent"),
            disableSortBy: true,
            accessor: (row: RiceStatTable) => (
              <>
                <button
                  onClick={() => {
                    var a = document.createElement("a");
                    a.setAttribute(
                      "href",
                      "data:text/tex;charset=utf-8," +
                        encodeURIComponent(row.sent)
                    );
                    a.setAttribute("download", row.resv_key + "_file_sent.xml");
                    a.click();
                    a.remove();
                  }}
                  className={"cursor-pointer text-cyan-600"}
                >
                  {t("buro.fileSentDownload")}
                </button>
              </>
            ),
          },
          {
            Header: "File ricevuto",
            disableSortBy: true,
            accessor: (row: RiceStatTable) => (
              <>
                <button
                  onClick={() => {
                    var a = document.createElement("a");
                    a.setAttribute(
                      "href",
                      "data:text/tex;charset=utf-8," +
                        encodeURIComponent(row.received)
                    );
                    a.setAttribute(
                      "download",
                      row.resv_key + "_file_received.xml"
                    );
                    a.click();
                    a.remove();
                  }}
                  className={"cursor-pointer text-cyan-600"}
                >
                  {t("buro.fileReceivedDownload")}
                </button>
              </>
            ),
          },
          {
            Header: t<string>("buro.status"),
            disableSortBy: true,
            accessor: (row: RiceStatTable) => (
              <>
                {row.status ? (
                  <div
                    className={
                      "rounded-2xl inline-block text-xs px-2 py-1 bg-green-100 font-medium text-green-800 text-white"
                    }
                  >
                    OK
                  </div>
                ) : (
                  <div
                    className={
                      "rounded-2xl inline-block text-xs px-2 py-1 bg-red-100 font-medium text-red-800 text-white"
                    }
                  >
                    ERROR
                  </div>
                )}
              </>
            ),
          },
        ];
      case "sef":
        return [
          {
            Header: t("reservations.code"),
            accessor: (row: SefTable) => (
              <>
                <button
                  onClick={() => history.push("/reservations/" + row.resv_key)}
                  className={"text-cyan-600"}
                >
                  {row.resv_key}
                </button>
              </>
            ),
          },
          {
            Header: t("buro.fileSent"),
            disableSortBy: true,
            accessor: (row: SefTable) => (
              <>
                <button
                  onClick={() => {
                    var a = document.createElement("a");
                    a.setAttribute(
                      "href",
                      "data:text/tex;charset=utf-8," +
                        encodeURIComponent(row.file_sent)
                    );
                    a.setAttribute("download", row.resv_key + "_file_sent.xml");
                    a.click();
                    a.remove();
                  }}
                  className={"cursor-pointer text-cyan-600"}
                >
                  {t("buro.fileSentDownload")}
                </button>
              </>
            ),
          },
          {
            Header: t("RAW"),
            disableSortBy: true,
            accessor: (row: SefTable) => (
              <>
                <button
                  onClick={() => {
                    var a = document.createElement("a");
                    a.setAttribute(
                      "href",
                      "data:text/tex;charset=utf-8," +
                        encodeURIComponent(row.raw_result)
                    );
                    a.setAttribute("download", row.raw_result + "_RAW.xml");
                    a.click();
                    a.remove();
                  }}
                  className={"cursor-pointer text-cyan-600"}
                >
                  RAW Download
                </button>
              </>
            ),
          },
          {
            Header: t("reservations.date"),
            accessor: (row: SefTable) => (
              <>{getHumanDateFromISO(row.date, false)}</>
            ),
          },
          {
            Header: t<string>("buro.status"),
            disableSortBy: true,
            accessor: (row: SefTable) => (
              <>
                {row.successfully ? (
                  <div
                    className={
                      "rounded-2xl inline-block text-xs px-2 py-1 bg-green-100 font-medium text-green-800 text-white"
                    }
                  >
                    OK
                  </div>
                ) : (
                  <div
                    className={
                      "rounded-2xl inline-block text-xs px-2 py-1 bg-red-100 font-medium text-red-800 text-white"
                    }
                  >
                    ERROR
                  </div>
                )}
              </>
            ),
          },
        ];
      case "uby":
        return [
          {
            Header: t("reservations.code"),
            accessor: (row: UbyTable) => (
              <>
                <button
                  onClick={() => history.push("/reservations/" + row.resv_key)}
                  className={"text-cyan-600"}
                >
                  {row.resv_key}
                </button>
              </>
            ),
          },
          {
            Header: t("buro.fileSent"),
            disableSortBy: true,
            accessor: (row: UbyTable) => (
              <>
                <button
                  onClick={() => {
                    var a = document.createElement("a");
                    a.setAttribute(
                      "href",
                      "data:text/tex;charset=utf-8," +
                        encodeURIComponent(row.data_sent)
                    );
                    a.setAttribute("download", row.resv_key + "_file_sent.xml");
                    a.click();
                    a.remove();
                  }}
                  className={"cursor-pointer text-cyan-600"}
                >
                  {t("buro.fileSentDownload")}
                </button>
              </>
            ),
          },
          {
            Header: t("RAW"),
            disableSortBy: true,
            accessor: (row: UbyTable) => (
              <>
                <button
                  onClick={() => {
                    var a = document.createElement("a");
                    a.setAttribute(
                      "href",
                      "data:text/tex;charset=utf-8," +
                        encodeURIComponent(row.raw_result)
                    );
                    a.setAttribute("download", row.raw_result + "_RAW.xml");
                    a.click();
                    a.remove();
                  }}
                  className={"cursor-pointer text-cyan-600"}
                >
                  RAW Download
                </button>
              </>
            ),
          },
          {
            Header: "Link",
            disableSortBy: true,
            accessor: (row: UbyTable) => (
              <>
                <button
                  onClick={() => {
                    fetchBucketItem(row.receipt_link, row.resv_key, "pdf");
                  }}
                  className={"text-cyan-600"}
                >
                  {row.resv_key}.pdf
                </button>
              </>
            ),
          },
          {
            Header: t("reservations.date"),
            accessor: (row: UbyTable) => (
              <>{getHumanDateFromISO(row.date, false)}</>
            ),
          },
          {
            Header: t<string>("buro.status"),
            disableSortBy: true,
            accessor: (row: UbyTable) => (
              <>
                {row.successfully ? (
                  <div
                    className={
                      "rounded-2xl inline-block text-xs px-2 py-1 bg-green-100 font-medium text-green-800 text-white"
                    }
                  >
                    OK
                  </div>
                ) : (
                  <div
                    className={
                      "rounded-2xl inline-block text-xs px-2 py-1 bg-red-100 font-medium text-red-800 text-white"
                    }
                  >
                    ERROR
                  </div>
                )}
              </>
            ),
          },
        ];
      default:
        break;
    }
  }

  function getColumsRossMilleId() {
    switch (type) {
      case "ross1000":
        return [
          {
            Header: t("raw_result"),
            accessor: (row: any) => (
              <>
                <button
                  onClick={() => {
                    var a = document.createElement("a");
                    a.setAttribute(
                      "href",
                      "data:text/tex;charset=utf-8," +
                        encodeURIComponent(row.raw_result)
                    );
                    a.setAttribute("download", row.resv + "_file_received.txt");
                    a.click();
                    a.remove();
                  }}
                  className={"cursor-pointer text-cyan-600"}
                >
                  {t("buro.fileReceivedDownload")}
                </button>
              </>
            ),
          },
          {
            Header: t("successfully"),
            accessor: (row: any) => <>{row.successfully.toString()}</>,
          },
          {
            Header: t("resvCODE"),
            accessor: (row: any) => (
              <button
                onClick={() => history.push("/reservations/" + row.resv)}
                className={"text-cyan-600"}
              >
                {row.resv}
              </button>
            ),
          },
          {
            Header: t("reservations.type"),
            accessor: (row: any) => <>{row.type}</>,
          },
        ];
      case "radar":
        return [
          {
            Header: t("raw_result"),
            accessor: (row: any) => (
              <>
                <button
                  onClick={() => {
                    var a = document.createElement("a");
                    a.setAttribute(
                      "href",
                      "data:text/tex;charset=utf-8," +
                        encodeURIComponent(row.raw_result)
                    );
                    a.setAttribute("download", row.resv + "_file_received.txt");
                    a.click();
                    a.remove();
                  }}
                  className={"cursor-pointer text-cyan-600"}
                >
                  {t("buro.fileReceivedDownload")}
                </button>
              </>
            ),
          },
          {
            Header: t("successfully"),
            accessor: (row: any) => (
              <>
                {row.successfully ? (
                  <div
                    className={
                      "rounded-2xl inline-block text-xs px-2 py-1 bg-green-100 font-medium text-green-800 text-white"
                    }
                  >
                    OK
                  </div>
                ) : (
                  <div
                    className={
                      "rounded-2xl inline-block text-xs px-2 py-1 bg-red-100 font-medium text-red-800 text-white"
                    }
                  >
                    ERROR
                  </div>
                )}
              </>
            ),
          },
          {
            Header: t("resvCODE"),
            accessor: (row: any) => (
              <button
                onClick={() => history.push("/reservations/" + row.resv)}
                className={"text-cyan-600"}
              >
                {row.resv}
              </button>
            ),
          },
          {
            Header: t("reservations.type"),
            accessor: (row: any) => <>{row.type}</>,
          },
        ];
      case "istat-trento":
        return [
          {
            Header: t("raw_result"),
            accessor: (row: any) => (
              <>
                <button
                  onClick={() => {
                    var a = document.createElement("a");
                    a.setAttribute(
                      "href",
                      "data:text/tex;charset=utf-8," +
                        encodeURIComponent(row.raw_result)
                    );
                    a.setAttribute("download", row.resv + "_file_received.txt");
                    a.click();
                    a.remove();
                  }}
                  className={"cursor-pointer text-cyan-600"}
                >
                  {t("buro.fileReceivedDownload")}
                </button>
              </>
            ),
          },
          {
            Header: t("successfully"),
            accessor: (row: any) => (
              <>
                {row.successfully ? (
                  <div
                    className={
                      "rounded-2xl inline-block text-xs px-2 py-1 bg-green-100 font-medium text-green-800 text-white"
                    }
                  >
                    OK
                  </div>
                ) : (
                  <div
                    className={
                      "rounded-2xl inline-block text-xs px-2 py-1 bg-red-100 font-medium text-red-800 text-white"
                    }
                  >
                    ERROR
                  </div>
                )}
              </>
            ),
          },
          {
            Header: t("resvCODE"),
            accessor: (row: any) => (
              <button
                onClick={() => history.push("/reservations/" + row.resv)}
                className={"text-cyan-600"}
              >
                {row.resv}
              </button>
            ),
          },
        ];
      default:
        break;
    }
  }

  return (
    <div>
      <BaseTable
        currentPage={currentPage}
        pageCount={Math.ceil(parseInt(data?.recordsTotal || "0") / 15)}
        identifierKey={
          type === "ross1000" || type === "radar" || type === "istat-trento"
            ? "id"
            : "code"
        }
        title={t("buro.receipts")}
        data={data ? data.data : []}
        loading={isFetching && isLoadingAccount}
        totalRows={parseInt(data?.recordsTotal || "0")}
        onSorting={handleSorting}
        displayRows={15}
        onFilterChange={(filters, page) => {
          setCurrentPage(page || 1);
          setFilters(filters);
          refetch();
        }}
        renderButtons={() => (
          <div className="flex lg:items-center">
            {/* scarico massivo delle ricevute */}
            {type === "portale-alloggiati" && (
              <Button
                onClick={() => {
                  setReceiptRequest("portale-alloggiati");
                }}
                className={"flex items-center text-sm font-medium rounded-2xl"}
                icon="document-download"
                size="small"
                color="info"
                label={t("buro.receiptsDownload")}
                disabled={parseInt(data?.recordsTotal || "0") === 0}
              />
            )}
            {type === "mossos-esquadra" && (
              <Button
                onClick={() => {
                  setReceiptRequest("mossos-esquadra");
                }}
                className={"flex items-center text-sm font-medium rounded-2xl"}
                icon="document-download"
                size="small"
                color="info"
                label={t("buro.receiptsDownload")}
                disabled={parseInt(data?.recordsTotal || "0") === 0}
              />
            )}
            {type === "uby" && (
              <Button
                onClick={() => {
                  setReceiptRequest("uby");
                }}
                className={"flex items-center text-sm font-medium rounded-2xl"}
                icon="document-download"
                size="small"
                color="info"
                label={t("buro.receiptsDownload")}
                disabled={parseInt(data?.recordsTotal || "0") === 0}
              />
            )}
          </div>
        )}
        filters={[]}
        columns={getColumns()}
        onEdit={(id) => {
          if (id) {
            setVisible(true);
            setRossMilleID(id);
          } // TODO
        }}
      ></BaseTable>
      <Modal visible={visible}>
        <ModalCard
          className={"w-full max-w-5xl"}
          title={t("Lista Prenotazioni")}
        >
          <ModalSection className="p-4">
            <BaseTable
              data={
                rossMilleRecipeiptID
                  ? rossMilleRecipeiptID.results.resv_results
                  : []
              }
              loading={isFetchingReceipt && isLoadingReceipt}
              displayRows={15}
              columns={getColumsRossMilleId()}
              currentPage={currentPage}
              identifierKey={"id"}
            ></BaseTable>
          </ModalSection>
          <ModalActions onClose={() => setVisible(false)} />
        </ModalCard>
      </Modal>
    </div>
  );
}
