import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
// import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import Button from "../Button";
import Checkbox from "../Checkbox";
import FormField from "../FormField";
import Icon from "../Icon";
import Modal from "../Modal";
import ModalActions from "../ModalAction";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";
import PageTitle from "../PageTitle";
import PageWrapper from "../PageWrapper";
import TextInput from "../TextInput";
import PreviousPage from "../PreviousPage";
import { useMutation, useQuery } from "react-query";
import {
  addApartment,
  fetchAlphaCountries,
  fetchPaymentMethod,
} from "../../shared/queries";
import { toast } from "react-toastify";
import SelectInput from "../SelectInput";
import { BaseSelectItem } from "../../types";
import i18n from "../../langs";

const AddApartmentDigital = () => {
  const { t } = useTranslation();
  const confirmationKey = t("general.iConfirm");
  const history = useHistory();
  /* useBreadcrumbs([
    { label: t("apartments.apartments"), route: "/apartments" },
    { label: t("New"), route: "/apartments/new" },
  ]); */

  const [confirmValue, setConfirmValue] = useState("");
  const [addForm, setAddForm] = useState(false);
  const [visible, setVisible] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [error, setError] = useState(false);
  const [formValue, setFormValue] = useState({
    name: "",
    public_name: "",
    address: "",
    city: "",
    zip: "",
    province: "",
    country_code: "IT",
    stripe_pm_id: "",
    smart_home: false,
  });

  const [localK, setLocalK] = useState("");

  const { data, isLoading } = useQuery("paymentMethods", fetchPaymentMethod);

  const { data: alphaCountries, isLoading: isLoadingAlpha } = useQuery<
    any,
    Error
  >(
    ["reservation"],
    () =>
      fetchAlphaCountries().then((data) => {
        const results = data?.results?.map((d: any) => {
          return {
            label: d.alpha_2 + " - " + d.name,
            value: d.alpha_2,
          };
        });
        return results;
      }),
    {
      onError: () => {
        toast.error(t("general.error"));
        history.goBack();
      },
    }
  );

  function getMethodList() {
    if (!data) return [];
    return data.results.map((pm) => {
      if (pm.card) {
        return {
          label:
            t("associateApartmentCard.card") +
            " •••• •••• •••• " +
            pm.card.last4 +
            " " +
            pm.card.exp_month +
            "/" +
            pm.card.exp_year +
            " (" +
            t("paymentMethods.expiresAt") +
            " " +
            pm.card.exp_month +
            "/" +
            pm.card.exp_year +
            ")",
          value: pm.id,
        };
      } else if (pm.sepa_debit) {
        return {
          label:
            t("associateApartmentCard.sepa") +
            " •••• •••• •••• " +
            pm.sepa_debit.last4,
          value: pm.id,
        };
      }
      return {
        label: "",
        value: "",
      };
    });
  }

  const handleSave = () => {
    setVisible(true);
  };

  const handleClose = () => {
    if (confirm) {
      setConfirm(false);
      setVisible(false);
      history.push(`/apartments/${localK}#modularity`);
    } else {
      setVisible(false);
      setConfirmValue("");
      setError(false);
    }
  };

  const handleConfirm = () => {
    if (!confirm) {
      if (confirmValue === confirmationKey) {
        addApartmentMutation.mutate(formValue);
      } else {
        setError(true);
      }
    } else {
      setConfirm(false);
      setVisible(false);
      setFormValue({
        name: "",
        public_name: "",
        address: "",
        city: "",
        zip: "",
        province: "",
        country_code: "",
        stripe_pm_id: "",
        smart_home: false,
      });
      history.push(`/apartments/${localK}#modularity`);
    }
  };

  const handleCheckboxChange = () => {
    setAddForm((prev) => !prev);
  };

  const handleAddHardChange = () => {
    setFormValue((prev) => {
      return {
        ...prev,
        smart_home: !prev.smart_home,
      };
    });
  };

  const addApartmentMutation = useMutation((data: any) => addApartment(data), {
    onSuccess: (data: any) => {
      setLocalK(data?.data);
      toast.success(t("general.requestCompleted"));
      setConfirm(true);
      setError(false);
    },
    onError: (error: Error) => {
      toast.error(t("general.requestError"));
    },
  });

  return (
    <PageWrapper>
      <PreviousPage
        className={"mb-4"}
        label={t("general.goBack")}
      ></PreviousPage>
      <PageTitle title={t("general.newApartment")} />
      <div
        className={
          "w-full px-4 py-3 text-sm border-gray-300 font-medium bg-red-200 rounded-3xl"
        }
      >
        <div className="flex space-x-2 px-5 md:text-sm text-xs">
          <div>
            <div className="mb-5">{t("addApartment.warning")}</div>
            {t("addApartment.listText")}
            <ul className="list-disc my-5">
              <li>{t("addApartment.listText1")}</li>
              <li>{t("addApartment.listText2")}</li>
              <li>{t("addApartment.listText3")}</li>
              <li>{t("addApartment.listText4")}</li>
            </ul>
            {t("addApartment.thanksWarning")}
          </div>
        </div>
      </div>
      <div className="bg-white shadow-xl p-6 flex-1 rounded-2xl mt-6 text-gray-500 space-y-5">
        <div className="md:flex justify-between gap-5">
          <FormField label={t("SpecialTags.Apartmentname")} className="flex-1">
            <TextInput
              value={formValue.name}
              onChange={(val) =>
                setFormValue((prev) => {
                  return {
                    ...prev,
                    name: val,
                  };
                })
              }
            />
          </FormField>
          <FormField
            label={t("apartments.publicName")}
            className="flex-1 mt-3 md:mt-0"
          >
            <TextInput
              value={formValue.public_name}
              onChange={(val) =>
                setFormValue((prev) => {
                  return {
                    ...prev,
                    public_name: val,
                  };
                })
              }
            />
          </FormField>
        </div>
        <div className="md:flex flex-row justify-between gap-5">
          <FormField label={t("addAddress.country")} className="flex-1">
            {/* <TextInput
              value={formValue.country_code}
              onChange={(val) =>
                setFormValue((prev) => {
                  return {
                    ...prev,
                    country_code: val,
                  };
                })
              }
            /> */}
            <SelectInput<BaseSelectItem, false>
              isLoading={isLoadingAlpha}
              placeholder={t("associateApartmentCard.chooseSource")}
              options={alphaCountries ? alphaCountries : []}
              value={alphaCountries?.find(
                (a: any) => a.value === formValue.country_code
              )}
              onChange={(item) => {
                if (item) {
                  setFormValue((prev) => {
                    return {
                      ...prev,
                      country_code: item.value,
                    };
                  });
                }
              }}
            />
          </FormField>
          <FormField label={t("general.city")} className="flex-1 mt-3 md:mt-0">
            <TextInput
              value={formValue.city}
              onChange={(val) =>
                setFormValue((prev) => {
                  return {
                    ...prev,
                    city: val,
                  };
                })
              }
            />
          </FormField>
        </div>
        <div className="md:flex justify-between gap-5">
          <FormField label={t("general.address")} className="flex-1">
            <TextInput
              value={formValue.address}
              onChange={(val) =>
                setFormValue((prev) => {
                  return {
                    ...prev,
                    address: val,
                  };
                })
              }
            />
          </FormField>
          <FormField
            label={t("addAddress.zip")}
            className="flex-2 mt-3 md:mt-0"
          >
            <TextInput
              maxLength={7}
              value={formValue.zip}
              onChange={(val) =>
                setFormValue((prev) => {
                  return {
                    ...prev,
                    zip: val,
                  };
                })
              }
            />
          </FormField>
          <FormField
            label={t("addAddress.province")}
            className="flex-3 mt-3 md:mt-0"
          >
            <TextInput
              maxLength={2}
              value={formValue.province}
              onChange={(val) =>
                setFormValue((prev) => {
                  return {
                    ...prev,
                    province: val,
                  };
                })
              }
            />
          </FormField>
        </div>
        <div className={"flex-1"}>
          <FormField label={t("associateApartmentCard.addPaymentMethod")}>
            <SelectInput<BaseSelectItem, false>
              isLoading={isLoading}
              placeholder={t("associateApartmentCard.chooseSource")}
              options={getMethodList()}
              onChange={(item) => {
                if (item) {
                  setFormValue((prev) => {
                    return {
                      ...prev,
                      stripe_pm_id: item.value,
                    };
                  });
                }
              }}
            />
          </FormField>
        </div>
        <div className="md:flex align-middle">
          <Checkbox
            size="sm"
            label={t("addApartment.smartRequest")}
            onChange={handleAddHardChange}
            hint={t("addApartment.smartRequestDesc")}
            checked={formValue.smart_home}
          />
        </div>
        <div className="md:flex align-middle">
          <Checkbox
            size="sm"
            label={t("addApartment.acceptTemsConds")}
            onChange={handleCheckboxChange}
            checked={addForm}
          />
          <span
            className="underline cursor-pointer text-blue-500 p-1 text-sm font-medium"
            onClick={() => {
              window.open(
                `/contratto${
                  i18n.language === "fr" ? "en" : i18n.language
                }.pdf`,
                "_blank"
              );
            }}
          >
            {t("SpecialTags.Contract")}
          </span>
        </div>

        <div className="my-10">
          <Button
            color="primary"
            label={t("general.save")}
            disabled={
              !formValue.name ||
              !formValue.public_name ||
              !formValue.country_code ||
              !formValue.city ||
              !formValue.address ||
              !formValue.zip ||
              !formValue.province ||
              !formValue.stripe_pm_id ||
              !addForm
            }
            onClick={handleSave}
          />
        </div>
      </div>
      <Modal visible={visible}>
        <ModalCard
          title={`${
            confirm
              ? t("addApartment.successAdd")
              : t("addApartment.confirmAdd")
          }`}
        >
          <ModalSection>
            {confirm ? (
              <PageWrapper>
                <div className="flex justify-center mb-5">
                  <Icon
                    name="badge-complete"
                    size="150px"
                    className="text-cyan-600"
                  />
                </div>
              </PageWrapper>
            ) : (
              <div className="text-gray-700 p-4 pt-0 border-gray-300">
                {t("general.write1")}
                <span className="font-bold">{confirmationKey}</span>{" "}
                {t("general.write2")}
                <div className="mt-2">
                  <TextInput
                    value={confirmValue}
                    onChange={(val) => setConfirmValue(val)}
                  />
                  {error && (
                    <span className="text-red-600 font-semibold">
                      {t("general.error")}
                    </span>
                  )}
                </div>
              </div>
            )}
          </ModalSection>
          <ModalActions
            saveDisabled={addApartmentMutation.isLoading}
            closeDisabled={addApartmentMutation.isLoading}
            isLoading={addApartmentMutation.isLoading}
            onSave={handleConfirm}
            saveLabel={`${
              confirm ? t("general.completed") : t("general.proceed")
            }`}
            onClose={handleClose}
            closeLabel={`${confirm ? t("general.close") : t("general.cancel")}`}
          />
        </ModalCard>
      </Modal>
    </PageWrapper>
  );
};

export default AddApartmentDigital;
