import i18n from '../langs';
import Button from './Button';

export default function ModalActions({
  onClose,
  onSave,
  saveLabel = i18n.t('general.save'),
  saveDisabled,
  closeDisabled,
  closeLabel = i18n.t('general.cancel'),
  isLoading = false,
  extraButtonAction,
  extraButtonLabel,
  extraButtonColor,
}: {
  onClose?: () => void;
  onSave?: () => void;
  saveLabel?: string;
  saveDisabled?: boolean;
  closeDisabled?: boolean;
  closeLabel?: string;
  isLoading?: boolean;
  extraButtonAction?: () => void;
  extraButtonLabel?: string;
  extraButtonColor?: 'primary' | 'negative' | 'success' | 'info';
}) {

  return (
    <div className={'flex space-x-2 p-4'}>
      {onSave && (
        <Button
          loading={isLoading}
          onClick={onSave}
          color={'primary'}
          disabled={saveDisabled}
          label={saveLabel}
        />
      )}
      {onClose && (
        <Button
          loading={isLoading}
          onClick={onClose}
          color={'negative'}
          flat
          label={closeLabel}
          disabled={closeDisabled}
        />
      )}
      {extraButtonLabel && (
        <Button
          loading={isLoading}
          onClick={extraButtonAction}
          color={extraButtonColor}
          flat
          label={extraButtonLabel}
        />
      )}
    </div>
  );
}
