import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react";
import { useMutation } from 'react-query';

import { CheckinExtraFilled, ReservationV3 } from "../../types"
import FormSectionTitle from "../FormSectionTitle"
import Icon from "../Icon"
import countryutils from "../../shared/countryutils";
import { downloadReservationContract } from "../../shared/queries";

type ReservationOptionsProps = {
  reservation: ReservationV3,
  onViewDocuments?: () => void
}

export default function ReservationOptions ({
  reservation,
  onViewDocuments
} : ReservationOptionsProps) {

  const { t } = useTranslation()

  const [invDataName, setInvDataName] = useState<string>('')


  const downloadReservationContractMutation = useMutation(
    (resv_key: string) =>
    downloadReservationContract(resv_key)
  );
  async function loadDatas () {
    if(reservation.invdata_country!== undefined && reservation.invdata_country!== null && reservation.invdata_country!== ''){
      const invdata = await countryutils.getCountry(reservation.invdata_country);
      if (invdata)
        setInvDataName(invdata.name)
    }
  }
  useEffect(() => {
    loadDatas()
}, [ reservation])

  return (
    <>

      <div className={'flex flex-wrap -mx-6'}>
        <div className={'w-full lg:w-1/2 px-6'}>

          <FormSectionTitle
            title={t('resv.resvOptions')}></FormSectionTitle>


          { (reservation.guest_filled_fname !== null ||reservation.guest_filled_lname !== null || reservation.guest_filled_mail !== null)&&
          <div className="flex">
            <div className={'mr-4 relative '}>
              <div className="w-3 h-3 rounded-full bg-gray-500"></div>
              <div
                className={'border-r-2 h-full absolute'}
                style={{ left: '44%', height: '95%' }}></div>
            </div>
            <div className={'w-full'}>
              <div className="bg-white w-full mb-6 border rounded-2xl p-4">
                <div className="mb-6 font-medium">{t('resv.filledData')}</div>
                <div className="flex mb-4 flex-wrap">
                  <div className={'text-sm w-full mb-2 xl:mb-0 xl:w-44 text-gray-500'}>{t('resv.filledFirstName')}</div>
                  <div className={'text-sm flex-1'}>
                      <div className={'mb-2 text-gray-600'}>
                        {reservation.guest_filled_fname}
                      </div>
                  </div>
                </div>
                <div className="flex mb-4 flex-wrap">
                  <div className={'text-sm w-full mb-2 xl:mb-0 xl:w-44 text-gray-500'}>{t('resv.filledLastName')}</div>
                  <div className={'text-sm flex-1'}>
                      <div className={'mb-2 text-gray-600'}>
                        {reservation.guest_filled_lname}
                      </div>
                  </div>
                </div>
                <div className="flex mb-4 flex-wrap">
                  <div className={'text-sm w-full mb-2 xl:mb-0 xl:w-44 text-gray-500'}>{t('resv.filledEmail')}</div>
                  <div className={'text-sm flex-1'}>
                      <div className={'mb-2 text-gray-600'}>
                        {reservation.guest_filled_mail}
                      </div>
                  </div>
                </div>
              </div>    
            </div>
          </div>
          }
          {reservation.extra && JSON.parse(reservation.extra).length>0 &&
                  <div className="flex">
                    <div className={'mr-4 relative '}>
                      <div className="w-3 h-3 rounded-full bg-gray-500"></div>
                      <div
                        className={'border-r-2 h-full absolute'}
                        style={{ left: '44%', height: '95%' }}></div>
                    </div>
                    <div className={'w-full'}>
                      <div className="bg-white w-full mb-6 border rounded-2xl p-4">
                        <div className="mb-6 font-medium">{t('resv.filledExtra')}</div>
                        {
                          reservation.extra && JSON.parse(reservation.extra).length>0 && JSON.parse(reservation.extra).map((extra: CheckinExtraFilled, index: number) => (
                            <div className="flex mb-4 flex-wrap">
                              <div className={'text-sm w-full mb-2 xl:mb-0 xl:w-44 text-gray-500'}>{extra.name}</div>
                              <div className={'text-sm flex-1'}>
                                  <div className={'mb-2 text-gray-600'}>
                                    {extra.value}
                                  </div>
                              </div>
                          </div>

                      ))}
                    </div>
                    </div>

                  </div>
        }

              { reservation.require_invoice&&
                  <div className="flex">
                    <div className={'mr-4 relative '}>
                      <div className="w-3 h-3 rounded-full bg-gray-500"></div>
                      <div
                        className={'border-r-2 h-full absolute'}
                        style={{ left: '44%', height: '95%' }}></div>
                    </div>
                    <div className={'w-full'}>
                      <div className="bg-white w-full mb-6 border rounded-2xl p-4">
                        <div className="mb-6 font-medium">{t('resv.invoiceData')}</div>
                            <div className="flex mb-4 flex-wrap">
                              <div className={'text-sm w-full mb-2 xl:mb-0 xl:w-44 text-gray-500'}>{t('resv.invoiceName')}</div>
                              <div className={'text-sm flex-1'}>
                                  <div className={'mb-2 text-gray-600'}>
                                  {(reservation.invdata_name !==null && reservation.invdata_name !==undefined && reservation.invdata_name !=='') ? reservation.invdata_name: t('resv.invoiceNotFilled') }
                                  </div>
                              </div>
                          </div>
                          <div className="flex mb-4 flex-wrap">
                              <div className={'text-sm w-full mb-2 xl:mb-0 xl:w-44 text-gray-500'}>{t('resv.invoiceVat')}</div>
                              <div className={'text-sm flex-1'}>
                                  <div className={'mb-2 text-gray-600'}>
                                    {(reservation.invdata_vat !==null && reservation.invdata_vat !==undefined && reservation.invdata_vat !=='') ? reservation.invdata_vat: t('resv.invoiceNotFilled') }
                                  </div>
                              </div>
                          </div>
                          <div className="flex mb-4 flex-wrap">
                              <div className={'text-sm w-full mb-2 xl:mb-0 xl:w-44 text-gray-500'}>{t('resv.invoicePassaport')}</div>
                              <div className={'text-sm flex-1'}>
                                  <div className={'mb-2 text-gray-600'}>
                                    {(reservation.invdata_passport !==null && reservation.invdata_passport !==undefined && reservation.invdata_passport !=='' ) ? reservation.invdata_passport: t('resv.invoiceNotFilled') }
                                  </div>
                              </div>
                          </div>
                          <div className="flex mb-4 flex-wrap">
                              <div className={'text-sm w-full mb-2 xl:mb-0 xl:w-44 text-gray-500'}>{t('resv.invoiceCountry')}</div>
                              <div className={'text-sm flex-1'}>
                                  <div className={'mb-2 text-gray-600'}>
                                    {(invDataName !==null && invDataName !==undefined && invDataName !=='') ?invDataName: t('resv.invoiceNotFilled') }
                                  </div>
                              </div>
                          </div>
                          <div className="flex mb-4 flex-wrap">
                              <div className={'text-sm w-full mb-2 xl:mb-0 xl:w-44 text-gray-500'}>{t('resv.invoiceSid')}</div>
                              <div className={'text-sm flex-1'}>
                                  <div className={'mb-2 text-gray-600'}>
                                    {(reservation.invdata_sid !==null && reservation.invdata_sid !==undefined && reservation.invdata_sid !=='') ? reservation.invdata_sid: t('resv.invoiceNotFilled') }
                                  </div>
                              </div>
                          </div>
                          <div className="flex mb-4 flex-wrap">
                              <div className={'text-sm w-full mb-2 xl:mb-0 xl:w-44 text-gray-500'}>{t('resv.invoicePec')}</div>
                              <div className={'text-sm flex-1'}>
                                  <div className={'mb-2 text-gray-600'}>
                                    {(reservation.invdata_pec !==null && reservation.invdata_pec !==undefined && reservation.invdata_pec !=='') ? reservation.invdata_pec: t('resv.invoiceNotFilled') }
                                  </div>
                              </div>
                          </div>
                          <div className="flex mb-4 flex-wrap">
                              <div className={'text-sm w-full mb-2 xl:mb-0 xl:w-44 text-gray-500'}>{t('resv.invoiceZip')}</div>
                              <div className={'text-sm flex-1'}>
                                  <div className={'mb-2 text-gray-600'}>
                                    {(reservation.invdata_zip !==null && reservation.invdata_zip !==undefined && reservation.invdata_zip !=='') ? reservation.invdata_zip: t('resv.invoiceNotFilled') }
                                  </div>
                              </div>
                          </div>
                          <div className="flex mb-4 flex-wrap">
                              <div className={'text-sm w-full mb-2 xl:mb-0 xl:w-44 text-gray-500'}>{t('resv.invoiceCity')}</div>
                              <div className={'text-sm flex-1'}>
                                  <div className={'mb-2 text-gray-600'}>
                                    {(reservation.invdata_city !==null && reservation.invdata_city !==undefined && reservation.invdata_city !=='') ? reservation.invdata_city: t('resv.invoiceNotFilled') }
                                  </div>
                              </div>
                          </div>
                          <div className="flex mb-4 flex-wrap">
                              <div className={'text-sm w-full mb-2 xl:mb-0 xl:w-44 text-gray-500'}>{t('resv.invoiceAddress')}</div>
                              <div className={'text-sm flex-1'}>
                                  <div className={'mb-2 text-gray-600'}>
                                    {(reservation.invdata_addr !==null && reservation.invdata_addr !==undefined &&reservation.invdata_addr !=='') ? reservation.invdata_addr: t('resv.invoiceNotFilled') }
                                  </div>
                              </div>
                          </div>
                    </div>
                    </div>

                  </div>
        }

            
          
          <div className="flex">
            <div className={'mr-4 relative '}>
              <div className="w-3 h-3 rounded-full bg-gray-500"></div>
              <div
                className={'border-r-2 h-full absolute'}
                style={{ left: '44%', height: '95%' }}></div>
            </div>
            <div className={'w-full'}>
              <div className="bg-white w-full mb-6 border rounded-2xl p-4">
                <div className="mb-6 font-medium">{t('resv.contractDocuments')}</div>
                <div className="flex mb-4 flex-wrap">
                  <div className={'text-sm w-full mb-2 xl:mb-0 xl:w-44 text-gray-500'}>{t('resv.sendDocuments')}</div>
                  <div className={'text-sm flex-1'}>

                    {
                      (!reservation.chk_documents) &&
                      <div className={'mb-2 text-gray-600'}>
                        {t('resv.sendDocumentsNotRequested')}
                        {!reservation.chk_photo && t('resv.mainGuest')}
                        {!reservation.chk_photo_all && ', ' + t('resv.otherGuests')}
                      </div>
                    }

                    {
                      (reservation.chk_documents) &&
                      <>
                        
                        {
                          (!reservation.chk_documents_done) &&
                          <div className={'mb-2 text-yellow-600'}>
                            {t('resv.waitingDocuments')}
                            {(reservation.chk_photo_all) && ' e per i secondari'}.
                          </div>
                        }

                        {
                          (reservation.chk_documents_done) &&
                          <div>
                            <div className={'text-green-600 font-medium flex items-center'}>
                              <Icon className={'mr-2'} name={'check'} size={'20px'}></Icon>
                              {t('resv.mainGuestDocReceived')} {(reservation.chk_photo_all) && ' ' + t('resv.otherGuests')}.
                            </div>
                            <button
                              onClick={() => {
                                if (onViewDocuments) onViewDocuments()
                              }}
                              className={'flex items-center mt-2 text-cyan-600 font-medium'}>
                                <Icon className={'mr-2'} name={'eye'} size={'20px'}></Icon> {t('resv.viewDocuments')}
                              </button>
                          </div>
                        }

                      </>
                    }

                  </div>
                </div>

                <div className="flex  pt-4 flex-wrap">
                  <div className={'text-sm w-full mb-2 xl:mb-0 xl:w-44 text-gray-500'}>{t('resv.contractSign')}</div>
                  <div className={'text-sm flex-1'}>
                    {
                      (!reservation.chk_contract) &&
                      <div className={'mb-1'}>
                        {t('resv.contractSignNotRequested')}
                      </div>
                    }

                    {
                      (reservation.chk_contract) &&
                      <>
                      {
                          (!reservation.chk_contract_done) &&
                          <div className={'mb-2 text-yellow-600'}>
                            {t('resv.waitingForContractSign')}
                          </div>
                        }

                        {
                          (reservation.chk_otp) &&
                            <div className={'mb-2 text-yellow-600'}>
                              {t('resv.OTPRequested')}
                          </div>
                        }

                        {
                          (reservation.guest_filled_phonenumber) &&
                          <div className={'mb-2 text-gray-600'}>
                            {t('resv.OTPActivated')}
                            <div>{reservation.guest_filled_phonenumber} ({reservation.nexmo_verify_code})</div>
                          </div>
                        }

                        {
                          (reservation.chk_contract_done) &&
                          <div className={'text-green-600 font-medium flex items-center space-x-1'}>
                            <Icon className={'mr-2'} name={'check'} size={'20px'}></Icon>
                            {t('resv.contractSigned')}
                            <div>
                              <a href='#'
                              onClick={() => {
                                console.log("oooooooooooooooooooooooo")
                                downloadReservationContractMutation.mutate(reservation.resv_key)
                              }
                              }> {t('resv.downloadContract')}</a>
                            </div>
                          </div>
                        }
                      </>
                    }
                    
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex">
            <div className={'mr-4 relative'}>
              <div className="w-3 h-3 rounded-full bg-gray-500"></div>
              <div className={'border-r-2 h-full absolute'} style={{ left: '44%', height: '95%' }}></div>
              <div className={'absolute -bottom-1 h-3 w-3 rounded-full bg-gray-200'}></div>
            </div>
            <div className={'w-full'}>
              <div className="bg-white w-full border rounded-2xl p-4">
                <div className="mb-6 font-medium">{t('resv.paymentAndTaxes')}</div>
                <div className="flex mb-4 flex-wrap">
                  <div className={'text-sm w-full mb-2 xl:mb-0 xl:w-44 text-gray-500'}>{t('resv.stayPayment')}</div>
                  <div className={'text-sm flex-1'}>

                    {
                      (!reservation.chk_paystay) &&
                      <div className={'mb-2 text-gray-600'}>
                        {t('resv.stayPaymentNotRequested')}
                      </div>
                    }

                    {
                      (reservation.chk_paystay) &&
                      <div className={'mb-2 text-yellow-600'}>
                        {t('resv.stayPaymentRequested')} {(reservation.chk_paystay_chargeguest) && t('resv.paymentChargeGuestCommission')}.
                      </div>
                    }
                    
                    {
                      (reservation.chk_paystay_done) &&
                      <div className={'text-green-600 font-medium flex items-center'}>
                        <Icon className={'mr-2'} name={'check'} size={'20px'}></Icon>
                        {t('resv.stayPaymentReceived')}
                      </div>
                    }

                  </div>
                </div>

                <div className="flex  mb-4 pt-4 flex-wrap">
                  <div className={'text-sm w-full mb-2 xl:mb-0 xl:w-44 text-gray-500'}>{t('resv.tax')}</div>
                  <div className={'text-sm flex-1'}>

                    {
                      (!reservation.chk_citytax) &&
                      <div className={'mb-2 text-gray-600'}>
                        {t('resv.taxPaymentNotRequested')}
                      </div>
                    }

                    {
                      (reservation.chk_citytax) &&
                      <div className={'mb-2 text-yellow-600'}>
                        {t('resv.taxPaymentRequested')} {(reservation.chk_citytax_chargeguest) && t('resv.paymentChargeGuestCommission')}.
                      </div>
                    }

                    {
                      (reservation.chk_citytax_done) &&
                      <div className={'text-green-600 font-medium flex items-center'}>
                        <Icon className={'mr-2'} name={'check'} size={'20px'}></Icon>
                        {t('resv.taxPaymentReceived')}
                      </div>
                    }
                    
                  </div>
                </div>

                <div className="flex  pt-4 flex-wrap">
                  <div className={'text-sm w-full mb-2 xl:mb-0 xl:w-44 text-gray-500'}>{t('resv.billingData')}</div>
                  <div className={'text-sm flex-1'}>

                    {
                      (!reservation.require_invoice) &&
                      <div className={'mb-2 text-gray-600'}>
                        {t('resv.billingDataNotRequested')}
                      </div>
                    }

                    {
                      (reservation.require_invoice) &&
                      <div className={'text-green-600 font-medium flex items-center'}>
                        <Icon className={'mr-2'} name={'check'} size={'20px'}></Icon>
                        {t('resv.billingDataRequested')}
                      </div>
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </>
  )

}