import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { fetchCounties } from '../shared/queries';
import {
  SingleApiResponseV3,
} from '../types';
import SelectInput from './SelectInput';

type CountySelectorProps = {
    initial: string;
  province: string;
  onChange?: (County: CountyOption) => void;
};

type CountyOption = {
  label: string;
  value: string;
};

type CountyItem = {
    cod: string;
    name: string;
    prov: string
}

export default function CountySelector({
    initial,
  province,
  onChange,
}: CountySelectorProps) {
  const [current, setCurrent] = useState<CountyOption | null>(null);

  const { data: counties, isLoading, refetch } = useQuery<
    SingleApiResponseV3<any>,
    Error
  >(['counties', province], () => fetchCounties(province));

  useEffect(()=>{
      const county = counties? Object.values(counties?.results).find((e:any)=>e.cod === initial) as CountyItem : ''
      console.log(county)
      if(county){
          setCurrent({label: county.name, value: county.cod})
      }

  },[initial, counties])

  useEffect(()=>{
    refetch()
    const county = counties? Object.values(counties?.results).find((e:any)=>e.cod === initial) as CountyItem : ''
    if(county){
        setCurrent({label: county.name, value: county.cod})
    }else{
      setCurrent(null)
    }
  }, [province])

  return (
    <SelectInput
      // menuShouldScrollIntoView={false}
      menuPortalTarget={document.body}
      onChange={(item) => {
        setCurrent(item as CountyOption);
        if (onChange && item) onChange(item as CountyOption);
      }}
      value={current}
      options={isLoading?[]:
          counties? Object.values(counties.results).map((e:any) => {
            return {
              label: e.name,
              value: e.cod,
            }}).sort((a,b)=>a.label>b.label?1:-1)
        :
        []
      }
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
    />
  );
}